import React, { Component } from 'react';
import './JoinButton.css';

// Here Will be join button and info about app downloading

class JoinButton extends Component {
    constructor(props) {
        super(props)

        this.handleJoinButtonClick = this.handleJoinButtonClick.bind(this);
    }

    generateDeeplink() {
        var isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod'), isAndroid = navigator.userAgent.match('Android');
        var iOSDeepLink = "";
        var androidDeepLink = "";

        if (isiOS) {
            return iOSDeepLink;
        } else if (isAndroid) {
            return androidDeepLink;
        }

        return "";
    }

    handleJoinButtonClick(id) {
        //stopsapp://stop/"+getStopIdString();
        let url = 'stopsapp://' + 'cube/' + id;
        // alert("URL: " + url);
        //let deeplink = this.generateDeeplink();

        // Simple device detection
        var isiOS = navigator.userAgent.match('iPad') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPod'), isAndroid = navigator.userAgent.match('Android');
        
        // For desktop browser, remember to pass though any metadata on the link for deep linking
        //var fallbackLink = 'http://example.com/my-web-app/' + window.location.search+window.location.hash;
        var fallbackLink = 'https://stops.com'; //'http://example.com/my-web-app/' + window.location.search+window.location.hash;

        // Mobile
        if (isiOS || isAndroid) {
            // Load our custom protocol in the iframe, for Chrome and Opera this burys the error dialog (which is actually HTML)
            // for iOS we will get a popup error if this protocol is not supported, but it won't block javascript
            
            //document.getElementById('loader').src = 'stopsapp://' + id; //window.location.search+window.location.hash;
            setTimeout(function () {
				window.location.replace(url); }
            , 2000);
		 
		//console.log('AppStopMobile', appStop);
		//let operationSystem = this.state.env.os;

		//console.log('operationSystem',operationSystem);
		//window.location = appStop;
    //window.open(appStop,'_blank');

            // The fallback link for Android needs to be https:// rather than market:// or the device will try to 
            // load both URLs and only the last one will win. (Especially FireFox, where an "Are You Sure" dialog will appear)
            // on iOS we can link directly to the App Store as our app switch will fire prior to the switch
            // If you have a mobile web app, your fallback could be that instead. 
            fallbackLink = isAndroid ? 'https://play.google.com/store/apps/details?id=com.stops.dev' :
                                    'itms-apps://itunes.apple.com/app/stops-app/id1349673464?mt=8' ;
        }

        // Now we just wait for everything to execute, if the user is redirected to your custom app
        // the timeout below will never fire, if a custom app is not present (or the user is on the Desktop)
        // we will replace the current URL with the fallbackLink (store URL or desktop URL as appropriate)
        
        window.setTimeout(function (){ window.location.replace(fallbackLink); }, 1);
        /*
        window.setTimeout(function (){ 
            alert("Sorry, desktop version not yet available, try to run this page on mobile device (iOS/Android)");
         }, 1);
         */
    }

    render() {
        const {cubeid} = this.props;

        return (
            <div className="join-container-desktop">
                <div>
                    <button onClick={() => this.handleJoinButtonClick(cubeid)} id="join-btn">View in Stops app</button>
                </div>
                <div>
                    <span>Requires mobile Stops app (
                        <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/stops-app/id1349673464">iOS</a> or <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.stops.dev">Android</a>)</span>
                </div>
            </div>
        );
    }
}

export default JoinButton;