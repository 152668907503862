import React, { Component } from 'react';
import './StopsNearby.css';
import Slider from "react-slick";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick.css";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
import stopsImg from './stops.png';
import { getCategory, getFirstCategoryColor } from '../../../Storage/Storage';
import StopsWatermarkBubble from '../../../Stop/StopDesktop/StopsWatermarkBubble/StopsWatermarkBubble.js';

class StopsNearby extends Component {
    constructor(props) {
        super(props);
        
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

 
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
	
	numProps = (obj) =>{
		let c = 0;
		for (var key in obj) {
			if (obj.hasOwnProperty(key)) ++c;
		}
		return c;
    }	
    

	
   prevnext = (sWCount) =>{
       sWCount = 0;  // Natan ask so. No Need arrows in mobile
        if (sWCount > 3){
        return (
            <div className="stops-nearby-navigation">
            <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
            <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
            </div>
        )
        }
        else
        {
            return(
            ""
            )
        }
    }
	
getDescription = (descr) =>{
        let ret = descr.trim().split(".");
    
        if (ret[0].length> 14){
            
            let wCount = descr.split(".").count;
            if (wCount > 2){
                wCount = 2;
            }
            else
            {
                if (wCount > 1){
                    wCount = 2;
                }
                else
                 {
                wCount = 1;
                 }
            }
    
           
            ret[0] = "";
            for (var i = 0; i<=wCount;++i){
    
                ret[0] += descr.split(" ")[i] + " ";
            }

            if(ret[0].length > 14)
            {
                ret[0] = descr.split(" ")[0];
            }

            var position = descr.search(/[\u0590-\u05FF]/);
            if(position >= 0){
                //console.log('String contains Hebrew');
                ret[0] = '...'+ret[0];
            }
            else
            {
                ret[0] += '...';
            }
          
            
            //Rothschild Street 71. Four spacious rooms, 7 floor. Without mediation. Tel: +972524122039
            //01234567890123456
            //console.log("51 StopsNearBy ",ret[0])
            
        }
        else
        {
            if ( descr.trim().length > 0){
                ret[0] += "";
            }
            else
            {
                ret[0] = "Follow me...";
            }
        }
      
        return ret[0];
    }

    getImage = (img) => {
        if (img === null){
            return stopsImg;
        }
        else
        {
            return img;
        }

    }

 
	elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.sWCount === 1){
			ret = nameClass + "-left";
		}
		if (this.props.sWCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
	}	
	
	getAddress	= (elem) => {
		//console.log('stopaddress',elem.address);
		let adr = elem.address.street;
		let ret = "";
		if (adr){
			ret = (
			<div>
			{/*<i class="fa fa-home" aria-hidden="true"></i>*/}{adr} 
			</div>
					)
		}					
		return ret;
	}

	
    render() {
		/*
        let stopsWithin = JSON.parse(localStorage.getItem('stopsWITHIN'));

        let sWCount = this.numProps(stopsWithin);
         */
         
        var settings = {
            // infinite: (this.props.sWCount > 3),
            // slidesToShow: 3,
            // swipeToSlide: true
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true
        };

        if (this.props.sWCount > 0) {
            return (
                <div className="stops-nearby-mobile">
                    <div className="stops-nearby-navigation">
                        <span className="stops-nearby-title">
                            Stops nearby
                        </span>
                        {
                            this.prevnext(this.props.sWCount)
                        }
                    </div>
                    <Slider className="slider" ref={c => (this.slider = c)} {...settings}>
                        { Object.keys(this.props.within).map((elem, idx) => (
                            <div key={idx}>
                                <a href={this.props.within[idx].expandedUrl} alt={this.props.within[idx].description} target="_blank" rel="noopener noreferrer">
                                    <StopsWatermarkBubble imageURL={this.getImage(this.props.within[idx].preview)} borderColor={getFirstCategoryColor(this.props.within[idx].categories)} isMobile={true} />
                                    {/* <img className={this.elem2Left("stop-nearby-image")} src={this.getImage(this.props.within[idx].preview)} alt={this.props.within[idx].description} onClick={() => console.log("test")} /> */}
                                </a>
                                <div className={this.elem2Left("stop-slide-text")}>
                                    <a href={this.props.within[idx].expandedUrl} alt={this.props.within[idx].description} target="_blank" rel="noopener noreferrer"> 
                                        <div className={this.elem2Left("stop-slide-title")}>
                                            {this.props.within[idx].name}
                                        </div>
                                    </a>
                                    <div className={this.elem2Left("stop-address")}>
                                        {this.getAddress(this.props.within[idx])}							
                                    </div>
                                    <div className={this.elem2Left("stop-category")}>
                                        {getCategory(this.props.within[idx].categories)}
                                    </div>
                                </div>
                            </div>
                        )
                        )
                        }
                    </Slider>
                </div>
            )

        } else {
            return (<div></div>)
        }
    }
}

export default StopsNearby;