import React, { Component } from 'react';
import './Attachments.css';
import Slider from "react-slick";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick.css";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick-theme.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
import {getFileExt, formatKBSizeUnits, getShortName} from '../../../Storage/Storage';

class Attachments extends Component {
    constructor(props) {
        super(props);
        
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        // this.handleLightboxImage = this.handleLightboxImage.bind(this)
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    // handleLightboxImage = (image) => {
    //     this.setState({
    //         isOpen: true,
    //         image: attachment1
    //     });
    // }
	
	
    prevnext = (sliderNeed) =>{
        if (sliderNeed){
        return (
            <div>
            <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
            <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
            </div>
        )
        }
        else
        {
            return(
            <div>

            </div>
            )
        }
    }
	
	
    attachementToLeft = (numItems) => {
        let ret = "attachment-item";
        if (numItems === 1) {
            ret = "attachment-item-1"
        }
        return ret;
    }
	

    elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.attsCount === 1){
			ret = nameClass + " " +nameClass+"-left";
		}
		if (this.props.attsCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
	}
		

    render() {
		
        var settings = {
            infinite: false,
            slidesToShow: 2,
            // slidesToScroll: 1,
            clickable: true,
            swipeToSlide: true,
            arrows: false
        };


        const isOpen = this.state;
        if (this.props.attsCount > 0){
        return (
            <div className="attachments-mobile">
                <div className="attachments-navigation">
                    <span className="attachments-title">
                        Attachments
                    </span>
					{/*
                        
                            this.prevnext(this.props.attsCount > 2)
                    */}

                </div>
                <Slider className="slider"  {...settings}>
					{
                        Object.keys(this.props.attachements).map((elem,idx) => (
                            <div  key={idx}>
							<a className="attachement-link" href={this.props.attachements[idx].file.url} target="_blank" rel="noopener noreferrer">
								<div className ={this.elem2Left("attachment-item")}>
								{getFileExt(this.props.attachements[idx].file.file_name)}<br/>
								
								</div>
								<div className={this.elem2Left("attachment-slide-text")}>
                                <div className={this.elem2Left("attachment-slide-title")}>
                                    {(getShortName(this.props.attachements[idx].file.file_name))}
                                </div>
                                <div className={this.elem2Left("file-size")}>
                        {getFileExt(this.props.attachements[idx].file.file_name)}, {formatKBSizeUnits(this.props.attachements[idx].file.size)}
                                </div>
                        </div>
							</a>	
								
                            </div>
                        ))
                    }       
                </Slider>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.image}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )
                }
            </div>
        )}
        else
        {
            return(<div></div>)
        }
    }
}

export default Attachments;