import React, { Component } from 'react';
import './ArtBoardMobile.css';
import LargeCarousel from "../LargeCarousel/LargeCarousel.js";

// const titles = [
//     "Plaça Nova",
//     // "Stop! (Against Me! song)",
//     "Chicago Fans",
//     "Coffee Jazz Bar"
// ]
//
// const descriptions = [
//     '"Stop!" is a single by punk group Against Me!. It is the third single from their album New Wave. The video game, Rock Band, features this song as downloadable content. \n \n The single was released on CD and as a limited edition 7" vinyl single, 4500 on black vinyl and 500 on yellow vinyl.',
//     "Set within the shadow of the cathedral, this is the largest open-air space in the Gothic Quarter.",
//     "Cozy atmosphere inspires to work or just meet and chat with friends. Nice coffee and vegan sweets. Also they serve vine, but I hadn’t tried yet. Jazz nights on Fridays always attract a lot of guests, so come earlier, if you want to have a sit :)"
// ]

class ArtBoardMobile extends Component {
    constructor(props) {
        super(props);
        
        console.log("PROPS: ", this.props);

         this.state = {
             title: "",
             description: "",
             avatar: ""
         };

        // this.onChangeSlide = this.onChangeSlide.bind(this);
    }

    // onChangeSlide = (current) => {
    //     this.setState({
    //         title: titles[current],
    //         description: descriptions[current]
    //     });
    // }

    render() {
        const { title, description, avatar } = this.props;

        /*
        <div className="cube-info" style={{textAlign: 'center'}}>
            Public Cube, 32 members
        </div>
        */

        return (
            <div className="art-board-cube-mobile">

                <LargeCarousel image={avatar} onChangeSlide={this.onChangeSlide} />

                <div className="container">
                    <div className="title" style={{textAlign: 'center'}}>
                        {title}
                    </div>
                    <p className="description" style={{textAlign: 'center'}}>
                        {description}
                    </p>
                </div>
            </div>
        );
    }
}

export default ArtBoardMobile;