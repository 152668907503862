import React from 'react';
import './Footer.css';
import footerLogo from './footer-logo.png'
import facebookIcon from './facebook-icon.svg'
import linkedinIcon from './linkedin-icon.svg'
import twitterIcon from './twitter-icon.svg'
import youtubeIcon from './youtube-icon.svg'


class  Footer  extends React.Component{
    
    render(){
        //console.log('Footer', getStopIdString())
    return (
        <div className="footer-mobile">
            <div className="footer-text">
            <img className="footer-logo" src={footerLogo} alt={footerLogo} />
                <div className="stops-link-text">
                    <a className="footer-link" href="https://www.stops.com/" target="_blank" rel="noopener noreferrer">stops.com</a>
                </div>

                <div>
                    <div className="blog-link-text">
                        <a className="footer-link" href="https://blog.stops.com/" target="_blank" rel="noopener noreferrer">blog.stops.com</a>
                    </div>
                    <div className="help-link-text">
                        <a className="footer-link" href="https://help.stops.com/" target="_blank" rel="noopener noreferrer">help.stops.com</a>
                    </div>
                    <div className="social-media">
                        <div className="social-media-title">
                            Social Media
                        </div>
                        <table className="social-media-table">
						<tbody>
                            <tr className="social-media-row">
                                <td>
                                    <a href="https://www.facebook.com/stopscom/" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={facebookIcon} alt={facebookIcon} />
                                    </a>
                                </td>
                                <td>
                                    <a href="https://www.linkedin.com/company/stopscom/" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={linkedinIcon} alt={linkedinIcon} />
                                    </a>
                                </td>
                                <td>
                                    <a href="https://twitter.com/stopscom" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={twitterIcon} alt={twitterIcon} />
                                    </a>
                                </td>
                                <td>
                                    <a href="https://www.youtube.com/channel/UC_1FYPeJzp3y2d0Wt7qnY6A" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={youtubeIcon} alt={youtubeIcon} />
                                    </a>
                                </td>
                            </tr>
						</tbody>	
                        </table>
                    </div>
                    <div className="legal">
                        © Stops.com, Ltd.  2016-2020
                    </div >
                    <div className="legal">
                        <a className="footer-link" href="https://legal.stops.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        &nbsp;& <a className="footer-link" href="https://legal.stops.com/termsofuse/" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    </div>
                </div>
            </div>
        </div>
    )}
}

export default Footer;