import React, { Component } from 'react';
import appStore from './app-store.png';
import googlePlay from './google-play.png';
import './AppLinks.css';

class AppLinks extends Component {
    

    render() {
        return (
            <div className="app-links">
                <div className="app-links-container">
                    <h1 className="download-stops">Download Stops:</h1>
                    <a href="https://play.google.com/store/apps/details?id=com.stops.dev" target="_blank" rel="noopener noreferrer">
                        <img className="google-play" src={googlePlay} alt="Google.com"/>
                    </a>
                    <a href="https://itunes.apple.com/us/app/stops-com/id1349673464?ls=1&mt=8" target="_blank" rel="noopener noreferrer">
                        <img className="app-store" src={appStore} alt="Apple.com"/>
                    </a>
                </div>
            </div>
        );
    }
}

export default AppLinks;