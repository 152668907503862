import React, { Component } from 'react';
import './StopsBubbleView.css';
//import { HereMap, Marker } from 'rc-here-maps';
// import './Map.css';
//import map from './map.jpg'
// import pin from './pin.png'
// import {getStopIdString,getHereMapsURI,getHereMapsDeskTop} from '../../../Storage/Storage';

class StopsBubbleView extends Component {

    // text = (text) => {
    //     const textLabel = this.props.text;
    // };

    // If contains enumerated number so -> true, else -> false (eg. collection list of stops)
    // isEnumeratedLabel = this.props.EnumeratedNumber === '' ? true : false;

    render() {
        let bubbleColor = this.props.bubbleColor;
        let bubbleGradientColor = 'radial-gradient(circle at 10px 10px,' + bubbleColor + ', #000)';

        let logoIconURL = this.props.bubbleLogo;
        const enumeratedNumber = this.props.enumeratedNumber;

        return (
        <div>
            <div className={"circle" + (enumeratedNumber ? "-enumerated" : "")} style={{background: bubbleGradientColor}}>
                {/* <img src={logoIconURL} className="stops-watermark" alt=""></img> */}
                {enumeratedNumber ? (
                    <div className="stops-watermark-text">{enumeratedNumber}</div>
                ) : (
                    <img src={logoIconURL} className="stops-watermark" alt=""></img>
                )}
            </div>
        </div>
        );
    }
}

export default StopsBubbleView;