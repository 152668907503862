import React, { Component } from 'react';
import './TravelDashboard.css';
//import travelMap from './travel-map.jpg'
//import pinPink from './pin-pink.svg'

class  TravelDashboard extends Component {
	render(){
		return (
			<div className="travel-dashboard-mobile">
				<div className="travel-info">
					<div className="dashboard-title">
							{this.props.title}
					</div>
					<div className="stops-distance-time">
						<span className="number-stops">{this.props.count} stops</span>
						<span className="distance-time"></span>
					</div>
				</div>
				<div className="dashboard-map">
					<img className="travel-map" src={this.props.thumb} alt={this.props.title} />
					
				</div>
			</div>
		)
	}
}

export default TravelDashboard;