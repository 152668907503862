import React from 'react';
import './PinInfo.css';
//import arrowDown from './arrow-down.png'
import pinBlue from './pin-blue.svg'
import pinPink from './pin-pink.svg'
import pinRed from './pin-red.svg'
import pinBlack from './pin-black.svg'
//import googleLocation from './google-location.png'
//import yelpLocation from './yelp-location.png'
//import wikiLocation from './wiki-location.png'
//import blueStar from './blue-star.svg'
//import blueStarHalf from './blue-star-half.svg'
//import yelpStars from './yelp-stars.png'

import { getFirstCategoryColor } from '../../../Storage/Storage';
import StopsWatermarkBubble from '../../../Stop/StopDesktop/StopsWatermarkBubble/StopsWatermarkBubble.js';

class PinInfo extends React.Component {

    pinColor = (num) => {
        let pinsColor = [
                            [pinBlack,"Black"],
                            [pinBlue, "Blue"],
                            [pinRed, "Red"],
                            [pinPink, "Pink"]];
        let modulus = num % 4;
        console.log('mudulus', modulus);
        return pinsColor[modulus];
    }

    pinNum = (position) => {
        //console.log('position',position);
        //console.log('position',position.trim().length);
        let ret = "pin-num";
        if (position < 10){
            ret = "pin-num-1";

        }
        return ret;
    }

    categoryColor = (stop) => {
        return getFirstCategoryColor(stop.categories);
    }

    render(){
        return (
            <div className="pin-info-mobile">
                <div className="pins-container">
                    <div className="pin-outer">
                        {
                            Object.entries(this.props.items).map((elem,idx) => (
                                <div className="pin-container pin-1" key={idx}>
                                    <div className="pin-header">
                                        <div className="pin-icon-container">
                                        <a href={elem[1].hereMapsURI} target="_blank" rel="noopener noreferrer">
                                            <img className="pin-icon" src={this.pinColor(elem[1].position)[0]} alt={this.pinColor(elem[1].position)[1]} />
                                            <div className={this.pinNum(elem[1].position)}>{elem[1].position}</div>
                                        </a>   
                                        </div>
                                        <div className="location-pic-container">
                                        <a href={elem[1].stopURL} target="_blank" rel="noopener noreferrer">
                                            <StopsWatermarkBubble borderColor={this.categoryColor(elem[1])} stopImageURL={elem[1].slider[0].photo.url} isSmallView={true} isMobile={true} />
                                            {/* <img className="location-pic" src={elem[1].slider[0].photo.url} alt={elem[1].title} /> */}
                                            </a>
                                        </div>
                                        <div className="pin-header-info">
                                            <div className="pin-name">
                                                {elem[1].title}
                                            </div>
                                            <div className="more-information">
                                            by {elem[1].createdBy}, {elem[1].created_at_month} {elem[1].created_at_day}, {elem[1].created_at_year}
                                            </div>
                                            <div className="pin-address" >
                                            {elem[1].fullAddress}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pin-description">
                                    {elem[1].description}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
  
                </div>
            </div>
        )
    }
}

export default PinInfo;