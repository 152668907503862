import React from 'react';
import './Footer.css';
import footerLogo from './footer-logo.png'
import facebookIcon from './facebook-icon.svg'
import linkedinIcon from './linkedin-icon.svg'
import twitterIcon from './twitter-icon.svg'
import youtubeIcon from './youtube-icon.svg'

function Footer() {
    return (
        <div className="footer-desktop">
            <table className="footer-table">
                <tbody>
                <tr>
                    <td className="titles">
                        <img className="footer-logo" src={footerLogo} alt={footerLogo} />
                    </td>
                    <td  className="center-column titles">
                        <strong>Download</strong>
                    </td>
                    <td className="titles">
                        <strong>Social Media</strong>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a className="footer-link" href="https://www.stops.com/" target="_blank" rel="noopener noreferrer">stops.com</a>
                    </td>
                    <td className="center-column">
                        <a className="app-link" href="https://apps.apple.com/us/app/stops-com/id1349673464" rel="noopener noreferrer">iOS App</a>
                    </td>
                    <td>
                        <div className="social-media">
                        <table className="social-media-table">
                        <tbody>
                            <tr className="social-media-row">
                                <td>
                                    <a href="https://www.facebook.com/stopscom/" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={facebookIcon} alt={facebookIcon} />
                                    </a>
                                </td>
                                <td>
                                    <a href="https://www.linkedin.com/company/stopscom/" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={linkedinIcon} alt={linkedinIcon} />
                                    </a>
                                </td>
                                <td>
                                    <a href="https://twitter.com/stopscom" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={twitterIcon} alt={twitterIcon} />
                                    </a>
                                </td>
                                <td>
                                    <a href="https://www.youtube.com/channel/UC_1FYPeJzp3y2d0Wt7qnY6A" target="_blank" rel="noopener noreferrer">
                                        <img className="social-media-icon" src={youtubeIcon} alt={youtubeIcon} />
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a className="footer-link" href="https://blog.stops.com/" target="_blank" rel="noopener noreferrer">blog.stops.com</a>
                    </td>
                    <td className="center-column">
                        <a className="app-link" href="https://play.google.com/store/apps/details?id=com.stops.dev">Android App</a>
                    </td>
                    <td />
                </tr>
                <tr>
                    <td>
                        <a className="footer-link" href="https://help.stops.com/" target="_blank" rel="noopener noreferrer">help.stops.com</a>
                    </td>
                    <td />
                    <td/>
                </tr>
                <tr>
                    <td className="legal">
                        © Stops.com, Ltd.  2016-2020
                    </td >
                    <td className="center-column" />
                    <td className="legal">
                        <a className="footer-link" href="https://legal.stops.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        &nbsp;& <a className="footer-link" href="https://legal.stops.com/termsofuse/" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Footer;