import React from 'react';
import './Weather.css';
//import icon from './weather-icon.png'
import farenheit from './degrees.png';
import celsius from './celsius.png';
// import {getJsonHeader} from '../../../Storage/Storage';

class  Weather extends React.Component {
   
    
    render(){
       

    return (
        <div className="weather-mobile" >
            <div className="weather-info">
                <div className="weather-title">Weather</div>
                <div className="weather-description">
                    <div className="description-text-wrapper">
                        <div className="now">Now</div>
                        <div className="description-text">{this.props.condition}</div>
                    </div>
                    <img className="weather-icon" src={this.props.weatherCode} alt={this.props.condition} />
                    <div className="temperature">
                        <div className="temperature-value">{this.props.current_temp_C}</div>
                        <img className="degrees" src={celsius} alt="Celsius" />
						<div className="temperature-value-f"> / {this.props.current_temp_F}</div>
                        <img className="degrees-f" src={farenheit} alt="Farenheit" />
                    </div>
                </div>
            </div>
        </div>
    );
	}
}

export default Weather;