import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
//import againstMe from './against-me.jpeg';
//import chicagoFans from './chicago-fans.jpg';
//import coffeeShop from './coffee-shop.png';

import './LargeCarousel.css';
import {numProps} from '../../../Storage/Storage';


import spotifyWatermark from './spotify-watermark.png';
import bookingWatermark from './booking-watermark.png';
import airbnbWatermark from './airbnb-watermark.png';
import britmovieWatermark from './britmovie-watermark.png';
import wallmartWatermark from './wallmart-watermark.png';
import applemusicWatermark from './applemusic-watermark.png';
import googleplayWatermark from './googleplay-watermark.png';
import youtubeWatermark from './youtube-watermark.png';


class LargeCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: this.props.currentSlide
            
        };
        this.handleChange = this.handleChange.bind(this);
    }

// test = (e, index) => {
//         this.setState({
//             visibleSlide: e.index
//         })
//         this.props.onChangeSlide(this.state.visibleSlide)
//         console.log("test")
//     };

    // change = () => {
    //     this.props.onChangeSlide(currentLeft)
    // }


    // componentDidUpdate() {
    //     // this.props.onChangeSlide(this.carousel.state.selectedItem)
    //     console.log("test")
    // }
	
    musicWatermark = (elem) => {
        let ret = "";
        //console.log('musicWatermark', elem);
        if (elem.type === "link"){
            
            // airbnb
            // apple
            // booking
            // britmovietours
            // googleplay
            // spotify
            // wallmart
            // youtube

            if (elem.subtype === "spotify"){
            ret = (<div className="waterdiv" >
            <img src={spotifyWatermark}    alt="Spotify" className="watermark"/>   
            </div>)
            }
            else if (elem.subtype === "booking"){
                ret = (<div className="waterdiv" >
                <img src={bookingWatermark}    alt="Booking" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "airbnb"){
                ret = (<div className="waterdiv" >
                <img src={airbnbWatermark}    alt="Airbnb" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "britmovietours"){
                ret = (<div className="waterdiv" >
                <img src={britmovieWatermark}    alt="Britmovietours" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "wallmart"){
                ret = (<div className="waterdiv" >
                <img src={wallmartWatermark}    alt="Wallmart" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "apple"){
                ret = (<div className="waterdiv" >
                <img src={applemusicWatermark}    alt="Apple" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "googleplay"){
                ret = (<div className="waterdiv" >
                <img src={googleplayWatermark}    alt="Google Play" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "youtube"){
                ret = (<div className="waterdiv" >
                <img src={youtubeWatermark}    alt="YouTube" className="watermark"/>   
                </div>)
            }
        }
        return ret;
    } 


    getCurrent() {
        const current = this.carousel.state.selectedItem
        return current
    }

    handleChange = selectedIndex => {
        console.log(selectedIndex)
        this.props.onChangeSlide(selectedIndex)
    };

    
    bubbleShow = () => {
        let ret = "";
        
        if (this.props.pixelBubble.defined){
           
            let elem  = document.querySelector('#pixel-img');

            if ((elem !== null) && (elem.offsetHeight > 0))
            {
                
                let centerY = elem.offsetHeight/2;
     
                let centerImgY = this.props.pixelBubble.photoY /2;
            
                let kY =  centerY / centerImgY;
				
				let realBubbleTop = elem.offsetHeight/2;
				let realBubbleLeft = elem.offsetWidth/2;
				
				if ((this.props.pixelBubble.bubbleTop !== 0) || (this.props.pixelBubble.bubbleLeft !== 0)){
					realBubbleTop       = this.props.pixelBubble.bubbleTop * kY;
               
					realBubbleLeft      = this.props.pixelBubble.bubbleLeft * kY;
				}
    

            ret = <div id="bubble-gum" className="bubble-gum" style={{top:realBubbleTop , left :realBubbleLeft
                  }}></div>
            }
           

        }
        return ret;

    }


	
    showEl = (elem) => {
        let ret = "";
        if (elem.subtype === "youtube"){
            
            ret = (            <div className="large-carousel-stop-mobile">

			{/*
                <iframe className="stops-video" title="Stop Video" width="550" height="400"
                         src={elem.href}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                />
			*/}
			<object data={elem.href} className="video-mobile" alt="Google"/>
            </div>)
        }
        else
        {
            if (this.props.stopType === "pixel"){
                ret =(<div>
                    <img id="pixel-img" src={elem.preview} alt=""/>
                        {this.bubbleShow()}
                    </div>)
            }
            else
            {
                 ret = (                        
                    <div   style={{height:"315px",backgroundSize: "contain",backgroundRepeat:"no-repeat",
                    backgroundImage: "url("+elem.preview+")", backgroundPosition: "center"}	}>
                            {this.musicWatermark(elem)}
                
                
            </div>);
        if (elem.href !== ""){
           ret = ( <a href={elem.href} target="_blank" rel="noopener noreferrer">
                             <div   style={{height:"315px",backgroundSize: "contain",backgroundRepeat:"no-repeat",
            backgroundImage: "url("+elem.preview+")", backgroundPosition: "center"}	}>
                                        {this.musicWatermark(elem)}
                                
                               
                            </div>
          </a>);
        }
        }
    }
        return ret;
    }
    

    
    render() {
        //let stops = JSON.parse(localStorage.getItem('stopsSTOPS'));
        //let slider      =  stops.items.filter(elem => ((elem.photo !== undefined ) && (elem.photo.id !== 0)));
		
		let sliderCount =numProps(this.props.slider);

       const settings = {
            infiniteLoop: (sliderCount > 1),
            showStatus: false,
            showThumbs: false,
            autoPlay : false,
            stopOnHover: true,
            transitionTime : 720,
            interval: 7000,
 
        };		

        if (sliderCount > 0){

        return (
            <div className="large-carousel-stop-mobile">
                <Carousel  {...settings}>
                     {
                        Object.keys(this.props.slider).map((elem,idx) => (
                            <div key={idx}  >
							 {this.showEl(this.props.slider[idx])}
                                   
                            </div>
                        ))
                    }
                </Carousel>
                {/*<button onClick={(index) => console.log(index)}>test</button>*/}
            </div>
        )
    }
    else
    {
        return (<div></div>)
    }
    }
}

export default LargeCarousel;