import React from 'react';
import './Navbar.css';
import stopsLogo from './stops-logo.png'
//import {getStopsHeader,getStopIdString} from '../../../Storage/Storage';


class  Navbar extends React.Component {
  	profileImg = (img) => {
		
		if (img === ""){
			return(
			<div></div>
			)
		}
		else
		{
			return (
			<div>
			<img className="profile-pic" src={img} alt="" />
			</div>
			)
		}
	}
    render(){
        
    return (
        <div className="navbar-mobile">
            <img className="stops-logo" src={stopsLogo} alt={stopsLogo} />
            {this.profileImg(this.props.img)}
            <div className="navbar-text">
                <div className="shared-by">Shared by:</div>
                <div className="name"><strong>{this.props.fullName}</strong></div>
            </div>
        </div>
    );
}
}

export default Navbar;