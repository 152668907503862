import React from 'react';
import './Comments.css';
import {getUsrImage,getCommentsDate} from '../../../Storage/Storage.js';
//import commentIcon1 from './comment-icon1.png'
//import commentIcon2 from './comment-icon2.svg'
//import commentIcon3 from './comment-icon3.png'
//import arrowDown from './arrow-down.png'

class Comments extends React.Component {

    render()
    {
        if ( this.props.comCount > 0){
            return (
                <div className="comments-desktop-qa">
                    <div className="comments-title">
                        Comments <span className="num-comments">{this.props.comCount}</span>
                    </div>
                    <div className="comments-container">
                    {
                    Object.entries(this.props.comments).map((elem,idx) =>(
                     
                        <div className="comment-outer" key={idx}>
                        
                        <div className="comment-container">
                            <div className="comment-info">
                                <img className="comment-icon" src={getUsrImage(this.props.comments[idx])} alt={this.props.comments[idx].user_name} />
                                <div className="comment-name-date">
                                    <div className="comment-name">
                                    {this.props.comments[idx].user_name}
                                    </div>
                                    <div className="comment-date" >
                                        {getCommentsDate(this.props.comments[idx].updated_at)}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="comment">
                            {this.props.comments[idx].content}
                    
                            </div>
                        </div>
                    </div>






                    ))
                    }
                    
                       
              
                 
                    </div>
                    {/*
                    <div className="show-more">
                        <div className="show-more-text">
                            Show more <img className="arrow-down" src={arrowDown} alt={arrowDown} />
                        </div>
                    </div>
                    */}
                </div>

            )
        }
        else
        {
            return (<div></div>)
        }

    }
}

export default Comments;