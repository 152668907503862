import React from 'react';
import { BrowserRouter as Router, Route,  Switch } from "react-router-dom";
import ScrollToTop from "../ScrollToTop.js"
import Stop from '../Stop/Stop.js'

import Collections from '../Collections/Collections.js'

import Cube from '../Cube/Cube.js'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import PixelIcon from '@material-ui/icons/Palette';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import MovieIcon from '@material-ui/icons/Movie';
// import CollectionsIcon from '@material-ui/icons/Collections';
// import BusinessIcon from '@material-ui/icons/Business';
// import CubeIcon from '@material-ui/icons/Wallpaper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './BottomNav.css';
// import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    bigIndicator: {
        height: 5,
        backgroundColor: 'darkblue'
        // top: 0
    },
    scrollButtons: {
        color: 'white',
        width: '8%'
    }
}));

const styles = {
    unselected: {
        color: 'lightskyblue',
        minWidth: '33.33%'
    },
    selected: {
        // color: 'lightcyan',
        color: 'lightskyblue',
        fontWeight: 'bold',
        minWidth: '33.33%'
    }
};

export default function ScrollableTabsButtonForce() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <div className={classes.root}>
            <Router>
                <Switch>
                    <Route path= '/stop' component={Stop} />
                    <Route path="/collection" component={Collections}/>
                    <Route path="/cube" component={Cube}/>
                    <Route component={Stop} />      
                </Switch>
            </Router>
        </div>
    );
}