// ==================================
// NEED TO REFACTOR THIS FILE!!! (not created by me [FIL.SVIATOSLAV])
// ==================================

// Imports
import CategoryKeeper from '../CategoryKeeper/CategoryKeeper.js';

// ==================================
// Class to data access
//  Static Functions :
//
//  getWeather()
//  getWithin()
//  getCoord()
//  getHereMapsDeskTop()
//  getHereMapsMobile()
//  getHereMapsURI()
//  getUser()
//  getWiki()
//  getStops()
//  getIdString()
// 
//========================================

   function getStopsHTTPHeader () {

        return (       
		 {"Content-Type": "application/json",
           "Authorization": "Bearer e2829ef1e511560fbba3e625425d9cd2226ba129161cfcc350e987669e484044"
          }
            )
	}
	
	function getStopsDevHTTPHeader() {
		return (
			{
				"Content-Type": "application/json",
				"Authorization": "Bearer eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXV0aC5zdG9wcy5jb20iLCJhdWQiOiJTdG9wcyIsInN1YiI6Nzc5LCJlbWFpbCI6ImFyY3RpYy50ZXN0ZXIxQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiQXJjdGljIFRlc3RlcjEiLCJnaXZlbl9uYW1lIjoiQXJjdGljIiwiZmFtaWx5X25hbWUiOiJUZXN0ZXIxIiwibmJmIjoxNTk1MjcyMDA4LCJpYXQiOjE1OTUyNzIwMDh9.PpLL59920vDc4LXVNBYcVjbLkUQsmDw7xS-14CL5V61NhUphuWnldiQ6N14PyxfbIs_Wq7LZrUmUtBZambL1K5kF7HEGhPjrpzUn0BYx9wH2pRk7hBBxrJ1lWVGVfV2nJSJkv-zDzqOE8B9CyyARAo9a37FSbXVu46AxCx91RvXiXNp5FCS-_CvbC5ZXxgfv62GijgCFZn9_Ow085_oPwU04fFkW_QKqjJmpBMEpADy7NiH8_T2oscTf2dux-IGtitFiIT297fo8i6FIEeegqix0uX4Uf32nHhWwS_I76c5UCAjf1Hea-DFmqIJ6dtv_VmyfyVoxuwZQq3hFDFdf9Ce8u5ZdJH5Ey0c_e0LYeZhG6sH036rq5VcFVHFaWIVoNbMHr83uaybBjrh928MVSPf_s44HW00P3p-lIOfgOT7qzqB5rkFBdpK35FP8vosuPLmjOccsPUo7xzI85cvyHvlmyJOnPxKxngrHSFvnbwOfK_eOvNA5O4Gka2A22nS-mh6sQzCCmlMjIJfgE0yizWyKPwL-OygDahJkZTTf7mf1DWNkvgSwfI6IRsz3jto_0Nw89kVexjRGvxSa7zhNbY8uz3yE4l04kMYRrnL2BQFo1vNU3n89YePFOuO3n7Tzxf5MrovvWCCwIfhyFcq_8PpvYqkqBgaQf9-eH8Sm89Q"
			}
		)
	}
//======================================
export function getWithinURL(lat,lng) {
	/*
    let suffix =  "&filter[type]=wiki&filter[or][type]=google&filter[or][type]=yelp";

    suffix =  "&filter[or][][type][]=wiki";
    suffix =  "&filter[or][][type][]=google";
    suffix = "&filter[type]=google";
    suffix = "&filter[type]=wiki";
    suffix = "&filter[or][][type][]=wiki&filter[or][][type][]=google&filter[or][][type][]=yelp";

    
    suffix = "&filter[type]=google";
    suffix += "&filter[type]=yelp";
	suffix += "&filter[type]=wiki";
	suffix = "&filter[or][][type][]=wiki&filter[or][][type][]=google&filter[or][][type][]=yelp";
	*/
    //let url = stopsNearByURL(lat,lng);
    // console.log('withinURL', url+suffix);
    return stopsNearByURL(lat,lng);  //+suffix;
}
//======================================
export function getYelpsStopsURL(lat,lng) {
	let suffix = "&filter[type]=yelp";
	return (stopsNearByURL(lat,lng)+suffix);
}
//======================================
export function getGoogleStopsURL(lat,lng) {
	let suffix = "&filter[type]=google";
	return (stopsNearByURL(lat,lng)+suffix);
}
//======================================
export function getWikiStopsURL(lat,lng) {
	let suffix = "&filter[type]=wiki";
	return (stopsNearByURL(lat,lng)+suffix);
	
}
//======================================
function stopsNearByURL(lat,lng){
	return "https://api.stops.com/stops?filter[lat]="+lat + "&filter[lng]="+lng+"&filter[radius]=2000";
}

/*
export function getMeetupsNearby(lat,lng) {
	const consumerKey = "7pc5bb0ir6qbbft8sctrj558vm"
	const consumerSecret = "shncv3g7m9ot5dsodls0f681fu"

	let distance = 1; // 100 Miles
	if (distance < 1) {
		distance = 1;
	} else if (distance > 100) {
		distance = 100;
	}

	let maxEndDate = new Date(); 
	maxEndDate.setDate(new Date().getDate() + 7); // Today + 7 days

	let formattedDate = date2str(maxEndDate, "yyyy-MM-dd") // yyyy-MM-dd

	/*
	let params = [
		"lat": lat,
		"lng": lng
	]
	*\/

	return "";
}
*/
//======================================

export   function  getStopsHeader(){
        let header = getStopsHTTPHeader();
          
        let obj = {
          method: 'GET',
          headers: header,
        };     

        return obj;
}

// ====================================
export   function  getStopsDevHeader(){
	let header = getStopsDevHTTPHeader();
	  
	let obj = {
	  method: 'GET',
	  headers: header,
	};     

	return obj;
}
// ====================================
 function   getJsonHttpHeader() {
        return(
              {
                "Content-Type": "application/json"
              }
        )
    }
//========================================
export function    getJsonHeader()  {
		let header = getJsonHttpHeader();
          
			let obj = {
              method: 'GET',
              headers: header,
            };
            return obj;
    }
//======================================== 
function routeMaps(route,pins){
    //console.log('route',route);
    //console.log('pins',pins);
    //let pointShape = "&poitxs=18&poitxc=white&poifc=blue";
    let imageMapURI = hereMapsPure("route")+route+pins+
    "&lc0=00ff00&sc0=000000&lw0=3&lc1=ff0000&sc1=0000ff&lw1=3&ml=eng";
   // console.log('imageMapURI:', imageMapURI);
    return imageMapURI;
}  
//========================================
function    hereMaps(lat,lng) 
        {
    
            // let appId="QEtz44OnTb2ZS9gyDXVl";
            // let appCode="6r0rVj-MmHKsUjNrcVIVsw";
    
 
			  let imageMapURI = hereMapsPure("mapview")+
                                                "&lat="+lat+
                                                "&lon="+lng+
                                                "&vt=0&ml=eng";

            return    imageMapURI;                                             
    
        }
    // =========================================
    function hereMapsAppCode () {
        let appId      ="QEtz44OnTb2ZS9gyDXVl";
        let appCode ="6r0rVj-MmHKsUjNrcVIVsw";
        return "?app_id="+appId+"&app_code="+appCode;
    }
    // =========================================

function  hereMapsPure(entity){
   
    let imageMapURI = "https://image.maps.api.here.com/mia/1.6/"+entity+hereMapsAppCode();

    return    imageMapURI; 

}  
   // ========================================= 
export function  getHereMapsURI(isMobile,lat,lng) {
  //console.log('isMobile',isMobile)
  // https://wego.here.com/directions/mix/31.88196,35.23876/?map=31.88196,35.23876
    let hereMapsURI  = "https://wego.here.com/directions/mix/:" +lat+","+ lng+"/?map="+lat+","+ lng+",18,normal";
    if (isMobile){
         hereMapsURI  = "https://wego.here.com/?map="+lat+","+ lng+",17,normal";
    }
    return hereMapsURI;
 }

 // =========================================       
// =========================================
 export function getHereMaps(isMobile, lat,lng)  {

                 let  mapDimension = "&z=18&w=2100&h=432";

                 if (isMobile){

                    mapDimension = "&z=16&w=720&h=240";
                 }

                return hereMaps(lat,lng) + mapDimension;
}
// =========================================

// =========================================
export function getHereMapsPreview(lat,lng)  {

	let  mapDimension = "&z=17&f=0&nodot=true&style=alps&w=250&h=150";

   return hereMaps(lat,lng) + mapDimension;
}
// =========================================

export function  getHereMapsRoute(isMobile,route,pins)  {
    let  mapDimension = "&w=900&h=675";

    if (isMobile){
       mapDimension = "&w=560&h=318";
    }
    return routeMaps(route,pins)+mapDimension;

}
/*
export function getWikiURL(lat,lng){
    let url = "https://en.wikipedia.org/w/api.php?action=query&prop=pageimages|info|extracts&"+
    "inprop=url&"+
    "piprop=thumbnail&"+
    "pithumbsize=144&"+
    "exintro=true&"+
    "exchars=400&"+
    "generator=geosearch&"+
    "ggscoord="+lat+"|"+lng+"&"+
    "ggsprimary=primary&"+
    "ggsradius=10000&"+
    "ggslimit=10&"+
    "format=json&origin=*";

    return url;
}
*/
//========================================
export function getWikiURI(lat,lng){

    let url = "https://en.wikipedia.org/w/api.php?action=query&prop=pageimages|info|extracts&"+
    "inprop=url&"+
    "piprop=thumbnail&"+
    "pithumbsize=144&"+
    "exintro=true&"+
    "exchars=400&"+
    "generator=geosearch&"+
    "ggscoord="+lat+"|"+lng+"&"+
    "ggsprimary=primary&"+
    "ggsradius=10000&"+
    "ggslimit=10&"+
    "format=json&origin=*";
    return url;
}

export function getCubeIdString()  {
	let id = "0";

	let pathName = window.location.pathname;
	//console.log("pathName: " + pathName);
	
	let pathNameArray = pathName.split('/').join('');
	//console.log("pathName array: " + pathNameArray);
	
	let typeLetter = pathNameArray[0]; // first letter s|c|l = stop|cube|collection
	//console.log("firstLetter: " + typeLetter);

	let hrfsa = (pathName).split("/"); // string array

	if ((hrfsa.length === 3))
	{
		id = hrfsa[2];
	}
	else
	{
		return ""
	}
	
	// console.log(id); //! delete

	return id;
}

export function getStopIdString()  {
		let id = "0";

		let pathName = window.location.pathname;
		//console.log("pathName: " + pathName);
		
		let pathNameArray = pathName.split('/').join('');
		//console.log("pathName array: " + pathNameArray);
		
		let typeLetter = pathNameArray[0]; // first letter s|c|l = stop|cube|collection
		//console.log("firstLetter: " + typeLetter);

		let hrfsa = (pathName).split("/"); // string array

		if ((hrfsa.length === 3))
		{
			id = hrfsa[2];
		}
		else
		{
			return ""
		}
		
		// console.log(id); //! delete

		return id;

}
    // =======================================
    export    function getStopPath()  {
		let path = "stop";
		let hrfsa = (window.location.pathname).split("/"); // string array
		 //console.log(hrfsa); //! delete
		if (hrfsa.length === 3  )
		{
			path = hrfsa[1];
		}
		else
		{
      
		}
		// console.log(path); //! delete
		return path;

    }
    // =======================================
    
	export function extractHostname (url)  {
        let hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
    
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
    
        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];
        
        return hostname;
        }
 // ==============================
        export function getWeateherURI(lat,lng){
            
            let d = new Date();

            let datestring = d.getFullYear() +"-"+("0"+(d.getMonth()+1)).slice(-2) + "-" +("0" + d.getDate()).slice(-2);
     
             let apiKey = '291b6dff8c0a4c99a79101351183001';
     
             let url = "https://api.worldweatheronline.com/premium/v1/weather.ashx?key=" +apiKey+
                                          "&q="+ lat+","+lng+
                                          "&date="+datestring+"&format=json" ;

            return url;
        }
//===============================
        export function numProps(obj) {
            let c = 0;
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) ++c;
            }
            return c;
        }
//===============================

export function getDescription (descr,strMinLen = 20) {
    let ret = descr.trim().split(".");
    
    if (ret[0].length>strMinLen){
        
        let wCount = descr.split(".").count;
        if (wCount > 2){
            wCount = 3;
        }
        else
        {
            if (wCount > 1){
                wCount = 2;
            }
            else
             {
            wCount = 1;
             }
        }

       
        ret[0] = "";
        for (var i = 0; i<=wCount;++i){

            ret[0] += descr.split(" ")[i] + " ";
        }
      
        ret[0] += '...';
        //Rothschild Street 71. Four spacious rooms, 7 floor. Without mediation. Tel: +972524122039
        //01234567890123456
        //console.log("51 StopsNearBy ",ret[0])
        
    }
    else
    {
        if ( descr.trim().length > 0){
            ret[0] += ".";
        }
        else
        {
            ret[0] = "Follow...";
        }
    }
  
    return ret[0]
}

//===============================


export function  pad(num, size)  {
    var s = "000000000" + num;
    return s.substr(s.length-size);
}

export function getUsrImage(elem)  {
// console.log(elem.user_img);
return elem.user_img;
}
//===============================
export function getCommentsDate(dte)  {
    let monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
          ];
    

    let dateObj  = new  Date(dte);
    

    let dObj = {
        month : monthNames[dateObj.getMonth()],
        day : pad(dateObj.getDate(),2),
        year: dateObj.getFullYear(),
        hour: pad(dateObj.getHours(),2),
        minutes: pad(dateObj.getMinutes(),2)
    }
    

    return dObj.month + " "+dObj.day+" "+dObj.year+ ", "+dObj.hour+":"+dObj.minutes;
   
}
//===============================
export function isMusicElem(elem,subtype){

    let ret = "link";
      
    let  musicLinks = [
                        ["open.spotify.com","spotify"],

                        ["music.apple.com","apple"],
                        ["geo.music.apple.com","apple"],
                        ["linkmaker.itunes.apple.com","apple"],
                        ["itunes.apple.com","apple"],


                        ["youtu.be","youtube"],
                        ["youtube.com","youtube"],
                        ["www.youtube.com","youtube"],
                        ["music.youtube.com","youtube"] ,

                        ["airbnb.com","airbnb"],
                        ["abnb.me","airbnb"],
                        ["www.airbnb.com","airbnb"],
                        ["www.abnb.me","airbnb"],

                        ["amazon.com", "amazon"], 

                        ["booking.com", "booking"], 

                        ["britmovietours.com", "britmovietours"], 

                        ["wallmart.com","wallmart"],

                        ["play.google.com","googleplay"],
   ]
let mCount = numProps(musicLinks);                            
 
    if (elem !== undefined){
        if (elem !== null)
        {
            if (elem.trim().length > 0){
                let hostname = extractHostname(elem);
                //console.log(hostname);
                for(var i=0; i< mCount; i++)
                {

                        if   (hostname.trim().toLowerCase().includes(musicLinks[i][0].trim().toLowerCase()))  {
                            //console.log("exists");
                            if (subtype === "subtype"){
                                ret = musicLinks[i][1];
                            }
                            else
                            {
                                ret = "link";
                            }
                            break;
                        }
                }
                
            }
        }
    }
    
    //console.log(ret);
    return ret;
}
//===============================
export function constructSlider(slider){
    let sCount = numProps(slider);
    //console.log('sCount:', sCount);
    let sRet = [];

    for (var i=0; i < sCount; i++)
    {
        //console.log(slider[i]);
        if (slider[i].type === "photo"){

            if ((slider[i].photo.id > 0) || (slider[i].photo.url !== "")){
            
                let slid = { id: slider[i].photo.id,
                                type: 'photo',
                                subtype: "", 
                                href : "",
                                preview: slider[i].photo.url,
                                file_name:slider[i].photo.file_name};

                sRet.push(slid);                

                //console.log(  'slid1',  slid);          
            }


          
        }
        else if(slider[i].type === "link"){
            
            let slid = { id: 0,
            type: isMusicElem(slider[i].link.url,""), 
            subtype: isMusicElem(slider[i].link.url,"subtype"),
            href : slider[i].link.url,
            preview: slider[i].link.preview,
            file_name:""};

            if (slid.subtype === "youtube")
            {
                
                slid.href = '//www.youtube.com/embed/'+getYouTubeId(slid.href)+"?autoplay=1";
            }

            sRet.push(slid);       



            //console.log(  'slid2',  slid);          
        }
        
    }
    //console.log(sRet);
    return sRet;
}
//===============================
export function getYouTubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}
//===============================
export function getCountry(code)
{
    let ret = code;

    //console.log('country Code',code);

    let countryTable = [
	['ABW',"Aruba"],
	['AFG',"Afghanistan"],
	['AGO',"Angola"],
	['AIA',"Anguilla"],
	['ALA',"Åland Islands"],
	['ALB',"Albania"],
	['AND',"Andorra"],
	['ARE',"United Arab Emirates"],
	['ARG',"Argentina"],
	['ARM',"Armenia"],
	['ASM',"American Samoa"],
	['ATA',"Antarctica"],
	['ATF',"French Southern Territories"],
	['ATG',"Antigua and Barbuda"],
	['AUS',"Australia"],
	['AUT',"Austria"],
	['AZE',"Azerbaijan"],
	['BDI',"Burundi"],
	['BEL',"Belgium"],
	['BEN',"Benin"],
	['BES',"Bonaire"],
	['BFA',"Burkina Faso"],
	['BGD',"Bangladesh"],
	['BGR',"Bulgaria"],
	['BHR',"Bahrain"],
	['BHS',"Bahamas"],
	['BIH',"Bosnia and Herzegovina"],
	['BLM',"Saint Barthélemy"],
	['BLR',"Belarus"],
	['BLZ',"Belize"],
	['BMU',"Bermuda"],
	['BOL',"Bolivia"],
	['BRA',"Brazil"],
	['BRB',"Barbados"],
	['BRN',"Brunei Darussalam"],
	['BTN',"Bhutan"],
	['BVT',"Bouvet Island"],
	['BWA',"Botswana"],
	['CAF',"Central African Republic"],
	['CAN',"Canada"],
	['CCK',"Cocos Islands"],
	['CHE',"Switzerland"],
	['CHL',"Chile"],
	['CHN',"China"],
	['CIV',"Côte d'Ivoire"],
	['CMR',"Cameroon"],
	['COD',"Republic of Congo"],
	['COG',"Congo"],
	['COK',"Cook Islands"],
	['COL',"Colombia"],
	['COM',"Comoros"],
	['CPV',"Cabo Verde"],
	['CRI',"Costa Rica"],
	['CUB',"Cuba"],
	['CUW',"Curaçao"],
	['CXR',"Christmas Island"],
	['CYM',"Cayman Islands"],
	['CYP',"Cyprus"],
	['CZE',"Czechia"],
	['DEU',"Germany"],
	['DJI',"Djibouti"],
	['DMA',"Dominica"],
	['DNK',"Denmark"],
	['DOM',"Dominican Republic"],
	['DZA',"Algeria"],
	['ECU',"Ecuador"],
	['EGY',"Egypt"],
	['ERI',"Eritrea"],
	['ESH',"Western Sahara"],
	['ESP',"Spain"],
	['EST',"Estonia"],
	['ETH',"Ethiopia"],
	['FIN',"Finland"],
	['FJI',"Fiji"],
	['FLK',"Falkland Islands"],
	['FRA',"France"],
	['FRO',"Faroe Islands"],
	['FSM',"Micronesia"],
	['GAB',"Gabon"],
	['GBR',"United Kingdom"],
	['GEO',"Georgia"],
	['GGY',"Guernsey"],
	['GHA',"Ghana"],
	['GIB',"Gibraltar"],
	['GIN',"Guinea"],
	['GLP',"Guadeloupe"],
	['GMB',"Gambia"],
	['GNB',"Guinea-Bissau"],
	['GNQ',"Equatorial Guinea"],
	['GRC',"Greece"],
	['GRD',"Grenada"],
	['GRL',"Greenland"],
	['GTM',"Guatemala"],
	['GUF',"French Guiana"],
	['GUM',"Guam"],
	['GUY',"Guyana"],
	['HKG',"Hong Kong"],
	['HMD',"Heard Island and McDonald Islands"],
	['HND',"Honduras"],
	['HRV',"Croatia"],
	['HTI',"Haiti"],
	['HUN',"Hungary"],
	['IDN',"Indonesia"],
	['IMN',"Isle of Man"],
	['IND',"India"],
	['IOT',"British Indian Ocean Territory"],
	['IRL',"Ireland"],
	['IRN',"Iran"],
	['IRQ',"Iraq"],
	['ISL',"Iceland"],
	['ISR',"Israel"],
	['ITA',"Italy"],
	['JAM',"Jamaica"],
	['JEY',"Jersey"],
	['JOR',"Jordan"],
	['JPN',"Japan"],
	['KAZ',"Kazakhstan"],
	['KEN',"Kenya"],
	['KGZ',"Kyrgyzstan"],
	['KHM',"Cambodia"],
	['KIR',"Kiribati"],
	['KNA',"Saint Kitts and Nevis"],
	['KOR',"Korea"],
	['KWT',"Kuwait"],
	['LAO',"Lao People's Democratic Republic"],
	['LBN',"Lebanon"],
	['LBR',"Liberia"],
	['LBY',"Libya"],
	['LCA',"Saint Lucia"],
	['LIE',"Liechtenstein"],
	['LKA',"Sri Lanka"],
	['LSO',"Lesotho"],
	['LTU',"Lithuania"],
	['LUX',"Luxembourg"],
	['LVA',"Latvia"],
	['MAC',"Macao"],
	['MAF',"Saint Martin"],
	['MAR',"Morocco"],
	['MCO',"Monaco"],
	['MDA',"Moldova"],
	['MDG',"Madagascar"],
	['MDV',"Maldives"],
	['MEX',"Mexico"],
	['MHL',"Marshall Islands"],
	['MKD',"North Macedonia"],
	['MLI',"Mali"],
	['MLT',"Malta"],
	['MMR',"Myanmar"],
	['MNE',"Montenegro"],
	['MNG',"Mongolia"],
	['MNP',"Northern Mariana Islands"],
	['MOZ',"Mozambique"],
	['MRT',"Mauritania"],
	['MSR',"Montserrat"],
	['MTQ',"Martinique"],
	['MUS',"Mauritius"],
	['MWI',"Malawi"],
	['MYS',"Malaysia"],
	['MYT',"Mayotte"],
	['NAM',"Namibia"],
	['NCL',"New Caledonia"],
	['NER',"Niger"],
	['NFK',"Norfolk Island"],
	['NGA',"Nigeria"],
	['NIC',"Nicaragua"],
	['NIU',"Niue"],
	['NLD',"Netherlands"],
	['NOR',"Norway"],
	['NPL',"Nepal"],
	['NRU',"Nauru"],
	['NZL',"New Zealand"],
	['OMN',"Oman"],
	['PAK',"Pakistan"],
	['PAN',"Panama"],
	['PCN',"Pitcairn"],
	['PER',"Peru"],
	['PHL',"Philippines"],
	['PLW',"Palau"],
	['PNG',"Papua New Guinea"],
	['POL',"Poland"],
	['PRI',"Puerto Rico"],
	['PRK',"North Korea"],
	['PRT',"Portugal"],
	['PRY',"Paraguay"],
	['PSE',"Palestine"],
	['PYF',"French Polynesia"],
	['QAT',"Qatar"],
	['REU',"Réunion"],
	['ROU',"Romania"],
	['RUS',"Russian Federation"],
	['RWA',"Rwanda"],
	['SAU',"Saudi Arabia"],
	['SDN',"Sudan"],
	['SEN',"Senegal"],
	['SGP',"Singapore"],
	['SGS',"South Georgia Islands"],
	['SHN',"Saint Helena"],
	['SJM',"Svalbard and Jan Mayen"],
	['SLB',"Solomon Islands"],
	['SLE',"Sierra Leone"],
	['SLV',"El Salvador"],
	['SMR',"San Marino"],
	['SOM',"Somalia"],
	['SPM',"Saint Pierre and Miquelon"],
	['SRB',"Serbia"],
	['SSD',"South Sudan"],
	['STP',"Sao Tome and Principe"],
	['SUR',"Suriname"],
	['SVK',"Slovakia"],
	['SVN',"Slovenia"],
	['SWE',"Sweden"],
	['SWZ',"Eswatini"],
	['SXM',"Sint Maarten"],
	['SYC',"Seychelles"],
	['SYR',"Syrian Arab Republic"],
	['TCA',"Turks and Caicos Islands"],
	['TCD',"Chad"],
	['TGO',"Togo"],
	['THA',"Thailand"],
	['TJK',"Tajikistan"],
	['TKL',"Tokelau"],
	['TKM',"Turkmenistan"],
	['TLS',"Timor-Leste"],
	['TON',"Tonga"],
	['TTO',"Trinidad and Tobago"],
	['TUN',"Tunisia"],
	['TUR',"Turkey"],
	['TUV',"Tuvalu"],
	['TWN',"Taiwan"],
	['TZA',"Tanzania"],
	['UGA',"Uganda"],
	['UKR',"Ukraine"],
	['UMI',"United States Minor Outlying Islands"],
	['URY',"Uruguay"],
	['USA',"USA"],
	['UZB',"Uzbekistan"],
	['VAT',"Holy See"],
	['VCT',"Saint Vincent and the Grenadines"],
	['VEN',"Venezuela (Bolivarian Republic of)"],
	['VGB',"Virgin Islands"],
	['VIR',"Virgin Islands"],
	['VNM',"Vietnam"],
	['VUT',"Vanuatu"],
	['WLF',"Wallis and Futuna"],
	['WSM',"Samoa"],
	['YEM',"Yemen"],
	['ZAF',"South Africa"],
	['ZMB',"Zambia"],
	['ZWE',"Zimbabwe"]
	];

    let countryLen =  numProps(countryTable);
    //console.log('country Len',countryLen);
    for(var i=0; i < countryLen; i++){
        //console.log('country Code',countryTable[i][0] );
        if (countryTable[i][0] ===  code){

            ret = countryTable[i][1];
            break;

        }

    }
    //console.log('country Code',ret);

    return ret;
}
//===============================
export function getFileExt(filename){
	let a = filename.split(".");
	if( a.length === 1 || ( a[0] === "" && a.length === 2 ) ) {
		return "";
	}
	return a.pop().toUpperCase();    // feel free to tack .toLowerCase() here if you want
}
//===============================
 

export const  getWeatherDescr = (code) => {
	    let weatherDescr = [
[395,'Moderate or heavy snow in area with thunder'],
[392,'Patchy light snow in area with thunder'],
[389,'Moderate or heavy rain in area with thunder'],
[386,'Patchy light rain in area with thunder'],
[377,'Moderate or heavy showers of ice pellets'],
[374,'Light showers of ice pellets'],
[371,'Moderate or heavy snow showers'],
[368,'Light snow showers'],
[365,'Moderate or heavy sleet showers'],
[362,'Light sleet showers'],
[359,'Torrential rain shower'],
[356,'Moderate or heavy rain shower'],
[353,'Light rain shower'],
[350,'Ice pellets'],
[338,'Heavy snow'],
[335,'Patchy heavy snow'],
[332,'Moderate snow'],
[329,'Patchy moderate snow'],
[326,'Light snow'],
[323,'Patchy light snow'],
[320,'Moderate or heavy sleet'],
[317,'Light sleet'],
[314,'Moderate or Heavy freezing rain'],
[311,'Light freezing rain'],
[308,'Heavy rain'],
[305,'Heavy rain at times'],
[302,'Moderate rain'],
[299,'Moderate rain at times'],
[296,'Light rain'],
[293,'Patchy light rain'],
[284,'Heavy freezing drizzle'],
[281,'Freezing drizzle'],
[266,'Light drizzle'],
[263,'Patchy light drizzle'],
[260,'Freezing fog'],
[248,'Fog'],
[230,'Blizzard'],
[227,'Blowing snow'],
[200,'Thundery outbreaks in nearby'],
[185,'Patchy freezing drizzle nearby'],
[182,'Patchy sleet nearby'],
[179,'Patchy snow nearby'],
[176,'Patchy rain nearby'],
[143,'Mist'],
[122,'Overcast'],
[119,'Cloudy'],
[116,'Partly Cloudy'],
[113,'Clear/Sunny']
    ];
	let ret = "";
	let wArr =weatherDescr.filter(elem => (elem[0]===code))[0];
	if (wArr === undefined){
		ret = "";
	}
	else
	{
		ret = wArr[1]
	}
	return ret;
}

//===============================
export const  formatKBSizeUnits = (bytes) => {
	if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(0) + " GB"; }
	else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(0) + " MB"; }
	else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(0) + " KB"; }
	else if (bytes > 1)           { bytes = bytes + " bytes"; }
	else if (bytes === 1)          { bytes = bytes + " byte"; }
	else                          { bytes = "0 bytes"; }
	return bytes;
  }

//===============================
export const getShortName = (name) => {
	let ret = name;
	let sLen = 15;
	if (name.length > sLen)
	{
		ret=  name.slice(0,(sLen-1)) +"...";
	}
	return ret;

	
	
}

// ==============================
// Usage: {getCategory(this.props.within[idx].categories)}
// ===============================	
export const getCategory = (category) => {
        let ret = "● ";

        if (category.length > 0)
        {

            //console.log('category', category);
            for(var i=0; i<category.length;i++)
            {
                //ret +=category[i] +",";
                //console.log('ret ', ret );
                if (i === 0) {
                    ret += getCategoryName(category[i]);
                }
                if (i === 1) {
                    ret += " and other";
                    break;
                } 
            }
        } else {
        	ret= "";
        }
        //console.log('ret ', ret );
        return ret;
}

// ==============================
// Usage: {getFirstCategoryColor(this.props.within[idx].categories)}
// ==============================
export const getFirstCategoryColor = (categories) => {
	if(categories === undefined || categories === [] || categories.length <= 0) {
		return "#45697b";
	}
	let keeper = new CategoryKeeper();
	let firstCategoryColor = keeper.colorOfCategory(categories[0]);
	return firstCategoryColor;
}

// ==============================
// Usage: getCategoryName(NAME) -> Category String (eg.: Other, Religion).
// ==============================
export const getCategoryName = (category) => {
	let keeper = new CategoryKeeper();
	let categoryName = keeper.nameOfCategory(category)
	return categoryName;
}

//===============================
export class fullAddress{
	constructor(params)
	{
		this.street   = (params.street   ? params.street  : "");
		this.numb     = (params.numb     ? params.numb    : "");
		this.city     = (params.city     ? params.city    : "");
		this.postcode = (params.postcode ? params.postcode: "");
		this.county   = (params.county   ? params.county  : "");
		this.district = (params.district ? params.district: "");
		this.state    = (params.state    ? params.state   : "");
		this.country  = (params.country  ? getCountry(params.country) : "");
		
		
		this.fullAddress = (this.street   + " "  + 
							this.numb     + ", " +
							this.city     + " "  + 
							this.postcode + " "  +
							this.county   + " "  + 
							this.district + " "  +
							this.state    + " "  + 
							this.country);
							
		if (this.fullAddress.trim() === ","){
			this.fullAddress = "";
		}							
		
	}
	
}
//===============================






