import React, { Component } from 'react';
import './GoogleArticlesNearby.css';
import Slider from "react-slick";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick.css";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
import googleLogo from './googlePlus1600.png';

//import googleLogo from 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png';
import {getDescription} from '../../../Storage/Storage.js';


class GoogleArticlesNearby extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
	
    prevnext = (count) =>{
        
        if (count){
        return (
            <div>
            <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
            <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
            </div>
        )
        }
        else
        {
            return(
            <div>

            </div>
            )
        }
    }
    
    /* // OLD
	getGoogleURL = (elem) => {
		let ret = "https://google.com";
		let search = "";
		
		
		if ((elem.content.google !== undefined) ||  (elem.content.google.photo !== null) || (elem.content.google.address !== "")){
			search = '?q="'+elem.content.google.address+', '+elem.name+'"';
		}
		
		return ret + search;	
    }
    */
    
    getGoogleURL = (elem) => {
        // https://www.google.com/maps/place/?q=place_id:ChIJp4JiUCNP0xQR1JaSjpW_Hms // Not opening in Google Maps App correctly
        // New Base URL
        // https://www.google.com/maps/search/?api=1&query=47.5951518,-122.3316393&query_place_id=ChIJKxjxuaNqkFQR3CK6O1HNNqY

        //let baseURL = "https://www.google.com/maps/place/?q=place_id:"

        let baseURL = "https://www.google.com/maps/search/?api=1"
        let id = elem.external_id;
        let location = [elem.location.longitude, elem.location.latitude];
        baseURL += "&query=" + location[0] + "," + location[1]; // 47.5951518,-122.3316393; 
        baseURL += "&query_place_id=" + id;

        if (id.length !== 0) {
            return baseURL;
        } else {
            return ""
        }
    }

    getGoogleImage = (elem) => {
		//let googleLogo =  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png";
        // console.log('getgoogleImage:',elem);
            
				let img = elem.preview;
				
				if (!img) {
					img = elem.photo;
				}
				
				if (!img) {
					img = elem.icon;
				}
				if (!img) {
					img = ""
				}
                //console.log('googlePhoto- 1',img);
                

				let bImageURL = "url("+img+")";
                
                //console.log('googlePhoto- 1',bImageURL);
                
                return(
                    <div>
                        <div className={this.elem2Left("go-container")} style={{backgroundImage: bImageURL}}>
                            <img className={this.elem2Left("go-logo")}  alt="Google sign-in" src={googleLogo} />
                        </div>

                        <p className={this.elem2Left("go-title")}>
                            { elem.name }
                        </p>
                    </div>
                )
    }

	elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.googleCount === 1){
			ret = nameClass + "-left";
		}
		if (this.props.googleCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		
		return ret;
	}	
	
	
    render() {
		
		
        var settings = {
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false
        };
     
        if (this.props.googleCount > 0){
        return (
            <div className="google-articles-nearby-desktop">
                <div className="google-navigation">
                    <span className="google-title">
                    Google nearby
                    </span>
                   
                    {
                        
                            this.prevnext(this.props.googleCount > 3)
                          
                            
                    }
                    
                    </div>
                <Slider className="slider" ref={c => (this.slider = c)} {...settings} >
                    
                        {
                             Object.entries(this.props.google).map((elem,idx) =>(
                                <div key={idx}>
                                        <div className="google-container">
										{
                                            <a href={this.getGoogleURL(this.props.google[idx])} target="_blank" rel="noopener noreferrer">
                                                {
                                                    this.getGoogleImage(this.props.google[idx])
                                                }    
                                            </a>
										}
										</div>
									
                                </div>

                                
                             )
                        
                        ) 
                    }
               
                </Slider>
            </div>
        )
        }
        else
        {
            return (<div></div>)
        }
    }
}

export default GoogleArticlesNearby;