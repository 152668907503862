import React, { Component } from 'react';
import './LocationWidgetMobile.css';
import {getHereMapsPreview, getHereMapsURI} from '../../../Storage/Storage';
import cubePlaceholder from '../LargeCarousel/cube_image_placeholder.png';

class LocationWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
          address: "",
          coordinates: ""
        };
      }

    render() {
        const { address, latitude, longitude, cubeThumbnail } = this.props;

        let mapStatisImage = getHereMapsPreview(latitude, longitude);
        let hereLocationLink = getHereMapsURI(false, latitude, longitude);
        let cubeImage = cubeThumbnail === "" ? cubePlaceholder : cubeThumbnail;

        return (
            <div className="location-widget-container-mobile">
            <a href={hereLocationLink} target="_blank" rel="noopener noreferrer" >
                <div className="location">
                    <div className="location-row">
                        <div className="map-container">
                            <img className="map-image" src={mapStatisImage} alt={address}></img>
                            <div className="location-pin-wrap">
                                <img className="location-pin" src={cubeImage} alt={cubeImage}></img>
                            </div>
                        </div>
                        <div className="texts-col">
                            <span className="map-title-text">Cube Location</span>
                            <span className="map-address-text">{address}</span>
                        </div>
                    </div>
                </div>
            </a>
            </div>
        );
    }
}

export default LocationWidget;