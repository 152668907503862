import React, { Component } from 'react';
import './WikiStopsNearby.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
//import wikiImage from './wiki-image.jpg';
import wikiLogo from './wiki-logo.png'

class WikiStopsNearby extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
	
	elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.wikiCount === 1){
			ret = nameClass + "-left";
		}
		if (this.props.wikiCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
	}
	
    getWikiImage = (elem) =>{
            
            if (!elem.preview ){
				
                return(
				<div>
                    <img className={this.elem2Left("wiki-image")} src="/img/wikistop-empty.png" alt={elem.name}/>
                    <img className={this.elem2Left("wiki-logo")} src={wikiLogo} alt={wikiLogo} />
                </div>
				
				)
            }
            else
            {
                return(
                    <div>
                    <img className={this.elem2Left("wiki-image")} src={elem.preview} alt={elem.name}/>
                    <img className={this.elem2Left("wiki-logo")} src={wikiLogo} alt={wikiLogo} />
                    </div>
                )
            }
    }

    prevnext = (count) =>{
        if (this.isDebug){
            console.log('count',count);
        }
        count = 0; // Natan ask so. No Need arrows in mobile
        if (count > 2){
        return (
            <div>
            <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
            <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
            </div>
        )
        }
        else
        {
            return(
            ""
            )
        }
    }

    getWikiURL = (element) => {
        let id = element.external_id;
        if (id.length !== 0) {
            let url = "http://en.wikipedia.org/?curid=" + id;
            return url
        } else {
            return ""
        }
    }

    render() {
		
        var settings = {
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true
            // centerPadding: '0px'
        };
        if (this.props.wikiCount > 0){
        return (
            <div className="wiki-stops-nearby-mobile">
                <div className="wiki-navigation">
                    <span className="wiki-title">
                    Wikipedia nearby
                    </span>
                     {
                        this.prevnext(this.props.wikiCount )    
                   }
                </div>
                <Slider className="slider" ref={c => (this.slider = c)} {...settings} >
                    {
                             Object.entries(this.props.wiki).map((elem,idx) =>(
                                 <div key={idx}>
                                         <div className="wiki-container">
                                            {/* {
                                                    this.getWikiImage(elem[1])
                                            } */}
                                            <a href={this.getWikiURL(elem[1])} target="_blank" rel="noopener noreferrer">
                                            {
                                                this.getWikiImage(elem[1])
                                            }
                                            </a>
										</div>
										<div className="wiki-slide-text">
                                        
										
											<div className={this.elem2Left("wiki-slide-title")}>
                                            {elem[1].name}
                                            </div>
										
                                        
                                    </div>
                                </div>

                                
                             )
                        
                        ) 
                    }
                </Slider>
            </div>
        )
    }
                else
    {return(<div></div>)}
    }
}

export default WikiStopsNearby;