import React, { Component } from 'react';
// import "../../CubeMobileOld/LargeCarousel/node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import againstMe from './against-me.jpeg';
import chicagoFans from './chicago-fans.jpg';
import coffeeShop from './coffee-shop.png';
import imagePlaceholder from './cube_image_placeholder.png';
import './LargeCarousel.css';

class LargeCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: this.props.currentSlide
        };
        // this.change = this.change.bind(this);
    }

// test = (e, index) => {
//         this.setState({
//             visibleSlide: e.index
//         })
//         this.props.onChangeSlide(this.state.visibleSlide)
//         console.log("test")
//     };

    // change = () => {
    //     this.props.onChangeSlide(currentLeft)
    // }


    // componentDidMount() {
    //     console.log("test5")
    // }

    render() {

        const settings = {
            infiniteLoop: true,
            showStatus: false,
            showThumbs: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            // onchange: () => this.props.onChangeSlide(this.carousel.state.selectedItem)
        };

        const { image } = this.props;
        const cubeImage = image === "" ? imagePlaceholder : image;

        return (
            <div className="large-carousel-cube-mobile">
                <img className="cube-photo mask-cube-image" src={cubeImage} alt={cubeImage} />
                
                {/*<Carousel className="carousel" ref={c => (this.carousel = c)} {...settings}*/}
                {/*          // onClickItem={() => console.log(this.test)}*/}
                {/*            // startOnLeave={() => console.log("test")}*/}
                {/*          onChange={(index) => this.props.onChangeSlide(index)}*/}
                {/*>*/}
                {/*    <div className="carousel-item" ref={c => (this.test = c)}>*/}
                {/*        <img className="carousel-image" src={coffeeShop}  alt={coffeeShop}/>*/}
                {/*    </div>*/}
                {/*    <div className="carousel-item">*/}
                {/*        <img className="carousel-image" src={chicagoFans}  alt={chicagoFans}/>*/}
                {/*    </div>*/}
                {/*    <div className="carousel-item">*/}
                {/*        <img className="carousel-image" src={againstMe}  alt={againstMe} />*/}

                {/*    </div>*/}
                {/*</Carousel>*/}
                {/*<button onClick={(index) => console.log(index)}>test</button>*/}
            </div>
        );
    }
}

export default LargeCarousel;