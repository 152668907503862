import React, { Component } from 'react';
import './YelpsNearby.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
//import yelpImage1 from './yelp-image1.png';
//import yelpImage2 from './yelp-image2.png';
//import yelpImage3 from './yelp-image3.png';
import yelpLogo from './yelp-logo.png'
import stars from './stars.png';

class YelpsNearby extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

      
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    
    getYelpImg = (elem) =>{
        let url = "";
        // Waiting for Dmitry to finished it!
        /*
        console.log("***********************************");
        console.log("elem.elem[1].content.yelp.url: " + elem.elem[1].content.yelp.url);
        console.log("***********************************");

        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
        //console.log("ID: " + elem.elem[1].id);

        console.log("####### STOP ##########");
        for (let [key, value] of Object.entries(elem.elem[1])) {
            console.log(`${key}: ${value}`);
        }
        console.log("####### END ##########");

        for (let [key, value] of Object.entries(elem.elem[1].content.yelp)) {
            console.log(`${key}: ${value}`);
        }

        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
        */

        if (elem.elem[1].content.yelp.url !== undefined) {
            url = elem.elem[1].content.yelp.url;
        } else {
            // console.log("YELP ELEMENT: ")
            // for (let [key, value] of Object.entries(elem.elem[1].content.yelp)) {
            //     console.log(`${key}: ${value}`);
            // }
            // console.log("************END************")
            url = "";
        }
        /*else
        {
            url = elem.elem[1].expandedUrl;
        }*/

        //console.log('Yelp elem',elem.elem[1]);
        //console.log('Yelp url',elem.elem[1].content.yelp.url);
		let yelpPreview = elem.elem[1].preview;
		//console.log('yelpPreview',yelpPreview);
		if (!yelpPreview ){
			yelpPreview = "/img/yelp-empty.png";
		}

        return (
            <div>
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <img className={this.elem2Left("yelp-image")} src={yelpPreview} alt={elem.elem[1].name}  />
                    <img className={this.elem2Left("yelp-logo")} src={yelpLogo} alt={yelpLogo} />
                </a>
                <div className={this.elem2Left("yelp-slide-text")}>
                            <div className="yelp-slide-title">
                                {elem.elem[1].name}
                            </div>
                            <div className="rating-info">
                            <table>
                            <thead></thead>
                                <tbody>
                                <tr>
                                    <td className="star-rating">
                                    {elem.elem[1].externalRating}
                                    </td>
                                    <td>
                                       <img className="stars" src={stars} alt={stars} />
                                    </td>
                                    <td className="num-ratings">
                                        (111)
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div className="yelp-info">
                                $$$$ •  {elem.elem[1].address.street}
                            </div>
                        </div>
            </div>
        )

    }


    prevnext = (count) =>{
        
        if (count){
        return (
            // <div>
            // <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
            // <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
            // </div>
            <div></div>
        )
        }
        else
        {
            return(
            <div>

            </div>
            )
        }
    }

	elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.yelpsCount === 1){
			ret = nameClass + "-left";
		}
		if (this.props.yelpsCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
	}
	  


    render() {
        var settings = {
            infinite: (this.props.yelpsCount  > 3),
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: true
        };
        if (this.props.yelpsCount > 0){
        return (
            <div className="yelps-nearby-desktop">
                <div className="yelps-navigation">
                    <span className="yelps-nearby-title">
                        Yelp nearby
                    </span>
                    {
                        this.prevnext(this.props.yelpsCount  > 3)
                   }
 
                </div>
                <Slider className="slider" ref={c => (this.slider = c)} {...settings}>

                { Object.entries(this.props.yelps).map((elem, idx) => (
                    <div key={idx}>
                        <div className="yelp-container">
                       {
                           this.getYelpImg({elem})
                       }
                                               
                        </div>
                    </div>
                                           ))
                }
                                           
                    


                </Slider>
            </div>
        )
        }
        else
        {
            return (<div></div>)
        }
    }
}

export default YelpsNearby;