import React, { Component } from 'react';
import './BottomSocialLinks.css';

// Social
import facebook_logo from './assets/facebook_logo.svg';
import instagram_logo from './assets/instagram_logo.png';
import linkdenin_logo from './assets/linkdenin_logo.svg';
// import product_hunt_logo from './assets/product_hunt_logo.svg';
import angel_logo from './assets/angel_logo.svg';
import twitter_logo from './assets/twitter_logo.svg';
import youtube_logo from './assets/youtube_logo.svg';

/*
import {
    facebook_logo,
    instagram_logo,
    linkdenin_logo,
    product_hunt_logo,
    twitter_logo,
    youtube_logo
} from './assets';
*/

class BottomSocialLinks extends Component {
    constructor(props) {
        super(props);
        
        this.state = {};
    }

    render() {
        // const { title, description } = this.props;

        return (
            <div className="bottom-social-links-mobile">
                <div className="container">
                    <div className="social-links">
                        <a target="_href" href="https://www.facebook.com/stopscom">
                            <img src={facebook_logo} alt="Facebook"></img>
                        </a>
                        <a target="_href" href="https://twitter.com/stopscom">
                            <img src={twitter_logo} alt="Twitter"></img>
                        </a>
                        <a target="_href" href="https://www.instagram.com/stops_com/">
                            <img src={instagram_logo} alt="Instagram"></img>
                        </a>
                        <a target="_href" href="https://www.linkedin.com/company/stopscom">
                            <img src={linkdenin_logo} alt="LinkedIn"></img>
                        </a>
                        <a target="_href" href="https://angel.co/company/stopscom">
                            <img src={angel_logo} alt="Product Hunt"></img>
                        </a>
                        <a target="_href" href="https://www.youtube.com/channel/UC_1FYPeJzp3y2d0Wt7qnY6A">
                            <img src={youtube_logo} alt="Youtube"></img>
                        </a>
                    </div>
                </div>
                <div className="copyright-container">
                    <p>© Stops.com, Ltd.  2016-2020</p>
                    <a href="https://legal.stops.com/privacypolicy">Privacy Policy</a> & <a href="https://legal.stops.com/termsofuse">Terms of Use</a>
                </div>
            </div>
        );
    }
}

export default BottomSocialLinks;