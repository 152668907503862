import React, { Component } from 'react';
import CollectionsDesktop from './CollectionsDesktop/AppCollectionsDesktop/AppCollectionsDesktop'
import CollectionsMobile from './CollectionsMobile/AppCollectionsMobile/AppCollectionsMobile.js'
import {AppEnv} from '../Storage/Environment.js';

class Collections extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
			env:AppEnv()
        };
    };

   
    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.handleWindowSizeChange);
    // }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        document.title = 'Collections';
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    render() {

        const {width} = this.state;
        const isMobile = width <= 700;

        if (isMobile) {
            return (
                <CollectionsMobile />
            );
        } else {
            return (
                <CollectionsDesktop />
            );
        }
    }
}

export default Collections;