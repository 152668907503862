import React, { Component } from 'react';
import './PhotoInfo.css';
import LargeCarousel from "../LargeCarousel/LargeCarousel.js";
import {numProps,getDescription} from '../../../Storage/Storage.js';

import downArrow from "./down-arrow.svg"


class PhotoInfo extends Component {
 
// ==================================================
 showBusiness = () => {
     let ret = "";
     if (this.props.stopType === "business"){
         //console.log('12   business');
         let phone = "";
         let email  = "";
         let website = "";
         let fullname = "";
         let workhoursCount = numProps(this.props.business.hours);
         
         let workSchedule="";

         let whoursArr = [
                                     {day: "", end: "-1", start: "-1",iday:"Sun",bold: false},
                                     {day: "", end: "-1", start: "-1",iday:"Mon",bold: false},
                                     {day: "", end: "-1", start: "-1",iday:"Tue",bold: false},
                                     {day: "", end: "-1", start: "-1",iday:"Wed",bold: false},
                                     {day: "", end: "-1", start: "-1",iday:"Thu",bold: false},
                                     {day: "", end: "-1", start: "-1",iday:"Fri",bold: false},
                                     {day: "", end: "-1", start: "-1",iday:"Sat",bold: false}
                                    
                                    ];

        let boldFace = true;
        for (var i = 0; i < workhoursCount ; i++ ) {
            whoursArr[i].day = this.props.business.hours[i].day;
            whoursArr[i].start = this.props.business.hours[i].start;
            whoursArr[i].end = this.props.business.hours[i].end;
			if (boldFace && (this.props.business.hours[i].start !== ""))
			{
				//whoursArr[i].bold = true;
				boldFace = false;
			}
        }

        //console.log("whoursArr", whoursArr);

                            

        //let now = new Date();
        //let dow = now.getDay();  
        //whoursArr[dow].bold = true;
        
        

        if ((this.props.business.first_name+this.props.business.last_name).trim() !== "" )
         {
            fullname = (
             <div className="contact-container">
            <div className="info-title">
                        CONTACT
            </div>
            <div className="info-text">
                {this.props.business.first_name+" "+this.props.business.last_name}
            </div>
        </div>)
         }


         if (this.props.business.phone.trim() !== "" )
         {
            phone = (
             <div className="phone-container">
            <div className="info-title">
                        PHONE
            </div>
            <div className="info-text">
                {this.props.business.phone}
            </div>
        </div>)
         }

         if (this.props.business.email.trim() !== "" ){
            email = (
                <div className="email-container">
                    <div className="info-title">
                                EMAIL
                     </div>
                     <div className="info-text">
					 <a href={"mailTo:"+this.props.business.email}  target="_blank" 
                                    rel="noopener nofollow noreferrer">

                            {this.props.business.email}
					</a>		
                    </div>

                </div>
            )
         }


         if (this.props.business.website.trim() !== "" ){
            website = (
                <div className="website-container">
                    <div className="info-title">
                            WEBSITE
                     </div>
                     <div className="info-text">
					 <a href={this.props.business.website}  target="_blank" 
                                    rel="noopener nofollow noreferrer">
                            {this.props.business.website}
					</a>		
                    </div>

                </div>
            )
         }



        if (workhoursCount > 0){
            workSchedule=(
						<div className="hours-container">
							
                            <div className="open-now-container">
                                <span className="info-title">WORKING HOURS</span>
                            </div>
                        <table className="working-hours">
						<thead></thead>
						<tbody>
                            {
                                Object.keys(whoursArr).map((elem,idx) => (
                                            
                                                    this.hoursElemShow(whoursArr[idx],idx)                                                    
                                            )
                                )
                           }
						</tbody>   
                         <tfoot></tfoot>   
                        </table>
                
        
        </div>)
        }

         ret = (
         <div className="business-info">
                {fullname}
                {phone}
                {email}
                {website}
                
                {workSchedule}
         </div>)
     }
     return ret;
 }
 // ==================================================
 hoursElemShow = (elem,idx) => {
     let ret = ""; 

     
     if ((elem.end !== "-1") && (elem.start !== "-1")){
            let stH = elem.start.substr(0,2) + ":" + elem.start.substr(2);
            let enH = elem.end.substr(0,2) + ":" + elem.end.substr(2);
            let s1 = <td>{elem.iday}</td>, 
                s2  = <td>{stH} {enH}</td>; 
            if (elem.bold){
                s1 = (<td><strong>{elem.iday}</strong></td>);
                s2 = (<td><strong>{stH} {enH}</strong></td>);


            }
            ret = (<tr key={idx} className="info-regular">{s1}{s2}</tr>);
            //console.log('hoursElemShow', elem);
     }
     else
     {
        ret = "";
		/*(<tr key={idx}>
                            
                                       <td className="business-closed"><strong>{elem.iday}</strong></td>
                            
                            
                                       <td className="business-closed"><strong>CLOSE</strong></td>
                            
                            
                        </tr>);*/
     }
     return ret;
 }

 // ==================================================

 

    showDescription = (text) => {
      var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
     
      return text.replace(urlRegex, function(url) {
          return '';
      });
    }  
  
    extractUrlDescription = (text,title) => {
     var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
 
	
	let url = ""; 
   
	let textOnly =  text.replace(urlRegex, function(url) {
        return '';   });
	
	let aSplit = text.split(" ");
	// find url in string
	for(var i=0; i < aSplit.length; i++)
	{
		url = aSplit[i].match(urlRegex);
		if (url)
		{
			break;
		}
	}
  
    if (!url){
          return "";
      }
      else{
          return (
              <p className="location-link"><a href={url} alt={title} title={title}
                                    target="_blank" 
                                    rel="noopener nofollow noreferrer">{url}</a>
                  
              </p>
          )
      }
    }

getSlice = (elem) => {
	let ret = elem;
	let sLen = 15;
	if (elem.length > sLen)
	{
		ret=  elem.slice(0,(sLen-1)) +"...";
	}
	return ret;
	
}

hashTags = () => {
	let hashCount = numProps(this.props.hashtags);
	let ret = "";
	
	if (hashCount >= 4 ){
		ret =(
			  <table>
				<tbody>
					<tr key={1}>
					
					
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[0])}</div>
						</td>
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[1])}</div>
						</td>
					</tr>
					<tr key={2}>
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[2])}</div>
						</td>
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[3])}</div>
						</td>
					</tr>
				</tbody>
              </table>
			  )
	}
	else if (hashCount === 3)
	{
		ret =(
			  <table>
				<tbody>
					<tr key={1}>
					
					
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[0])}</div>
						</td>
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[1])}</div>
						</td>
					</tr>
					<tr key={2}>
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[2])}</div>
						</td>
						
					</tr>
				</tbody>
              </table>)
	}
	else if (hashCount === 2)
	{
		ret =(
			  <table>
				<tbody>
					<tr key={1}>
					
					
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[0])}</div>
						</td>
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[1])}</div>
						</td>
					</tr>
					
				</tbody>
              </table>)
	}
	else if (hashCount === 1)
	{
		ret =(
			  <table>
				<tbody>
					<tr key={1}>
					
					
						<td className="hashtag-item" >
							<div className="hashtag">#{this.getSlice(this.props.hashtags[0])}</div>
						</td>
						
					</tr>
				</tbody>
              </table>)		
	}		
	return ret;
	
}
  
    render() {

        return (
            <div className="photo-info-stop-mobile">
               
                <LargeCarousel  slider={this.props.slider} pixelBubble={this.props.pixelBubble} stopType={this.props.stopType}/>
                
                <div className="photo-info-container">
                    <div className="title">
                        {this.props.title} 
                    </div>
                    <div className="date">
					{this.props.month} {this.props.day}, {this.props.year}
                    </div>
                    <div className="description">
                    {this.showDescription(this.props.description)}
                    </div>
                    {this.extractUrlDescription(this.props.description,this.props.title)}
					
					<div className="hashtag-div">
                    {this.hashTags()}
					{this.showBusiness()}
					</div>
					
                </div>
            </div>
        );
    }
}

export default PhotoInfo;