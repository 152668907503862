import Navbar from '../Navbar/Navbar.js';
import AppLinks from '../../../SharedComponents/Desktop/AppLinks/AppLinks.js';

import ArtBoard from '../ArtBoard/ArtBoard.js'

/*
import Map from '../Map/Map.js'
import LocationInfo from '../LocationInfo/LocationInfo.js'
import Weather from '../../../SharedComponents/Desktop/WeatherDesktop/Weather.js'
import StopsNearby from '../../../SharedComponents/Desktop/StopsNearbyDesktop/StopsNearby.js'
import Attachments from '../../../SharedComponents/Desktop/AttachmentsDesktop/Attachments.js'
import Members from '../Members/Members.js'
*/

// import WikiArticlesNearby from '../../../SharedComponents/Desktop/WikiArticlesNearbyDesktop/WikiArticlesNearby.js'
// import YelpsNearby from '../../../SharedComponents/Desktop/YelpsNearbyDesktop/YelpsNearby.js'
import Features from '../../../SharedComponents/Desktop/FeaturesDesktop/Features.js'
// import Comments from '../Comments/Comments.js'
import Footer from '../../../SharedComponents/Desktop/FooterDesktop/Footer.js'
import React from 'react';
import './AppCubeDesktop.css';
import CircleMembers from '../Members/CircleMembers.js';
import LocationWidget from '../LocationWidget/LocationWidget.js';
import AttachmentsWidget from '../AttachmentsWidget/AttachmentsWidget.js';
import JoinButton from '../JoinButton/JoinButton.js';

import {AppEnv} from '../../../Storage/Environment.js';
import {getStopsHeader, getJsonHeader, getCubeIdString} from '../../../Storage/Storage';
import BottomSocialLinks from '../BottomSocialLinks/BottomSocialLinks.js';

class AppCubeDesktop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      info: [],
      geoTag: [],
      stops: [],
      collections: [],
      medias: []
    };

  }

  async getCubeInfo() {
    let isDebug = true;
  
    //let header = getStopsHeader();
    let header = getStopsHeader();
    let jsonHeader = getJsonHeader();     
    let id = getCubeIdString();
    let url = "https://api.stops.com/cubes/" + id;
  
    fetch(url, header).then(data => {
        return (data.json());
    }).then(data => {        
    if (data.success){
        if (isDebug){
            console.log('Cube Data: ', data.data);
        }

        let cube = data.data;
        //this.setState({ error: null, isLoaded: true, info: data.data, geoTag: data.data.geoTag });
        this.setState({ error: null, isLoaded: true, info: data.data, geoTag: cube.geoTag, stops: [], collections: [], medias: [] });
        //this.getCubeStops(cube);

        this.loadCounters(cube);
    } else {
      //this.setState({ error: true, isLoaded: true, info: [], geoTag: [], stops: [], collections: [], medias: [] });
      this.moveToMainPage();
      console.log('Error: can not load cube info');
    }
    }).catch(function(err) {
      //console.log("Error: " = err);
        //this.moveToMainPage();
        window.location = "https://stops.com/";

        if (isDebug){	
           console.log(err);
           //alert('Collection Fetch Error : ', err); //! delete  ? what to do
        }
       else
       {
           //window.location = "https://stops.com/";
           //this.moveToMainPage();
       }			 
    });
  }

  async loadCounters(cube) {
    let id = cube.id;
    let geoTag = cube.geoTag;
    let header = getStopsHeader(); //getStopsHeader();

    let types = ['stops', 'collections', 'media'];
    let requests = types.map(type => fetch(`https://api.stops.com/cubes/${id}/${type}`, header));

    Promise.all(requests)
      .then(responses => {
        // все промисы успешно завершены
        for(let response of responses) {
          alert(`${response.url}: ${response.status}`); // покажет 200 для каждой ссылки
        }

        return responses;
    })
    // преобразовать массив ответов response в response.json(),
    // чтобы прочитать содержимое каждого
    .then(responses => Promise.all(responses.map(r => r.json())))
    // все JSON-ответы обработаны, users - массив с результатами
    .then(data => {        
      let allData = data.data;
      console.log("allData: " + allData);

      let collections = data.map(collection => collection.media.type === "collection");
      let stops = data.map(collection => collection.media.type === "stops");
      let medias = data.map(collection => collection.media.type !== ("collection" || "stops"));

      this.setState({ error: null, isLoaded: true, info: cube, geoTag: geoTag, stops: stops, collections: collections, medias: medias });
  });
}

  componentDidMount() {
    this.getCubeInfo();

    /*
    fetch("https://api.example.com/items")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.items
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
      */
  }

  render() {
    const { error, isLoaded, info, geoTag, stops, collections, medias } = this.state;
    const { address, latitude, longitude } = geoTag;
    const user = info.user;

    document.title = info.title + " cube";

    console.log("info: " + info);

    if(isLoaded) {
      return (
        <div className="app-cube-desktop">
          <div className="wrapper">
            <div className="show-background-image">
              <div className="top-components">
                <Navbar user={user} />
                <div className="outer">
                  <div className="inner">
                    {/* <AppLinks /> */} {/* Add it later */}
                    <div className="bottom-components">
                      <ArtBoard title={info.title} description={info.description} avatar={info.img} />
                      {/* <Map /> */}
                      {/* <LocationInfo /> */}
                      {/* <Weather /> */}
                      {/* <StopsNearby /> */}
                      {/* <Attachments /> */}
                      {/* <Members /> */}
                      <CircleMembers members={info.participants} />
                      <LocationWidget address={address} latitude={latitude} longitude={longitude} cubeThumbnail={info.img} />
                      <AttachmentsWidget stops={stops} collections={collections} medias={medias} />
                      <JoinButton />
                      {/* <Features /> */}
                      {/* <Footer /> */}
                    </div>
                  </div>
                    <BottomSocialLinks></BottomSocialLinks>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div  className="app-stop-load">
          <div className="loader-overlay">
            <div class="lds-ripple"><div></div><div></div></div>
          </div>
        </div>
      )
    }
  }
}

export default AppCubeDesktop;
