import React, { Component } from 'react';
import './LocationInfo.css';

import pin from './pin.png';
import pinIcon from './pin-icon.png';
import altitudeIcon from './altitude-icon.png';
// import bubble from './bubble.png';
// import {getStopsHeader,getStopIdString,getHereMapsURI,getHereMapsMobile} from '../../../Storage/Storage';

class LocationInfo extends Component {
 meters2feet = (mtr) => {
	
	return (mtr*3.28084).toFixed(0)
}   
seaLevel = () => {
	let ret="above";
	if (this.props.altitude < 0){
		ret = "below"
	}
	return ret;
} 
   render() {
    

    return (
	    <div>
	    
		
        <div className="location-info-stop-mobile" >
		
            <a href={this.props.hereMapsURI} target="_blank" rel="noopener noreferrer" >
			
                <div className="map">
                    <img className="map-image" src={this.props.imageMapURI} alt={this.props.city} />
                    <img className="pin" src={pin} alt={pin} />
                </div>
				
            </a>
			
            <div className="location">
                <div className="location-row">
                    <span><img className="pin-icon" src={pinIcon} alt={pinIcon} /></span>
                    <span className="location-text">
                        <a href={this.props.hereMapsurl} target="_blank" rel="noopener noreferrer" >
                            <p className="address-info">
                            
                            <span className="location-link"><strong>Location:</strong> {this.props.fullAddress}</span>
                            </p>
                        </a>
                    </span>
                </div>
            </div>
            <div className="altitude">
                <div className="altitude-row">
                    <span><img className="altitude-icon" src={altitudeIcon} alt={altitudeIcon} /></span>
                    <span className="altitude-text"><strong>Altitude:</strong> {this.props.altitude} m / {this.meters2feet(this.props.altitude)} ft <span className="sea-level">({this.seaLevel()} sea level)</span></span>
                </div>
            </div>
        </div>
	
		</div>
    );
   }
}

export default LocationInfo;