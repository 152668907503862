import Navbar from '../../../SharedComponents/Desktop/Navbar/Navbar.js';
import AppLinks from '../../../SharedComponents/Desktop/AppLinks/AppLinks.js';
import Meta from  '../../../Meta/Meta.js';
import TravelDashboard from '../TravelDashboard/TravelDashboard'
import PinInfo from '../PinInfo/PinInfo.js'
import Features from '../../../SharedComponents/Desktop/FeaturesDesktop/Features.js'
import Footer from '../../../SharedComponents/Desktop/FooterDesktop/Footer.js'
import React from 'react';

import './AppCollectionsDesktop.css';

import {getCollection} from '../../../Storage/dataBroker.js';
import {AppEnv} from '../../../Storage/Environment.js';


class  AppCollectionsDesktop extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			isLoad : false,

      // User
      id : 0, 
			firstName : "",
			lastName: "",
			img: "",
			unreadMsgCount : 0,
			fullName: "",

			// Collection
      title: "",
      count: "",
        
      month: "",
      day : "",
      year: "",
      url : "",
      thumb : "",
      thumbText: "",

      items: [],
          
      // facebook open graph       
      og : {},
      env: AppEnv(),
      
      route : [],
      routeString: "",
      routeURL: "",
	    hostname: window.location.hostname
    };
    

		  getCollection(this.onComplete);
    }
   
	onComplete = (isLoad, user,collection,items,og,route) =>{ 
    
  // When the code completes, do this
    
  //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
  //console.log(route);

    this.setState({
      isLoad : isLoad, 

      firstName :user.firstName,
      lastName : user.lastName,
      img : user.img,
      unreadMsgCount: user.unreadMsgCount,
      fullName : user.fullName,

      id: collection.id,
      title: collection.title,
      count: collection.count,
      
      month: collection.month,
      day : collection.day,
      year: collection.year,
      url : collection.url,
      thumb: collection.thumb,
      thumbText : collection.thumbText,

      items: items,
      // facebook open graph meta
      og:og,
      env: AppEnv(),
      
      route: route.route,
      routeString: route.routeString,
      routeURL : route.routeURL
    });
  }
	  
  
  componentDidMount(){
    this.setState({env: AppEnv()});
  }


  render(){
    //console.log('routeURL: ',this.state.routeURL);
  return (
      <div className="app-collections-desktop">
        <div className="wrapper">
          <div className="show-background-image">
            <div className="top-components">
              <Meta og={this.state.og} />
              <Navbar fullName={this.state.fullName} img={this.state.img} unreadMsgCount={this.state.unreadMsgCount}/>
              <div className="outer">
                <div className="inner">
                  <AppLinks />
                  <div className="bottom-components">
                    <TravelDashboard title={this.state.title} thumb={this.state.routeURL}  thumbText={this.state.thumbText}  count={this.state.count}/>
                    <PinInfo items={this.state.items} count={this.state.count}/>
                    <Features />
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
  }
}

export default AppCollectionsDesktop;
