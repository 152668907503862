import React, { Component } from 'react';
import './ArtBoard.css';
import LargeCarousel from "../LargeCarousel/LargeCarousel.js";

class ArtBoard extends Component {
    constructor(props) {
        super(props);
        
        console.log("PROPS: ", this.props);

         this.state = {
             title: "",
             description: "",
             avatar: ""
         };
    }

    render() {
        const { title, description, avatar } = this.props;

        return (
            <div className="art-board-cube-desktop">

                <LargeCarousel image={avatar} onChangeSlide={this.onChangeSlide} />

                <div className="container">
                    <div className="title" style={{textAlign: 'center'}}>
                        {title}
                    </div>
                    <p className="description" style={{textAlign: 'center'}}>
                        {description}
                    </p>
                </div>
            </div>
        );
    }
}

export default ArtBoard;