import React, { Component } from 'react';
// import "../../../Cube/--CubeMobileOld/LargeCarousel/node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


import spotifyWatermark from './spotify-watermark.png';
import bookingWatermark from './booking-watermark.png';
import airbnbWatermark from './airbnb-watermark.png';
import britmovieWatermark from './britmovie-watermark.png';
import wallmartWatermark from './wallmart-watermark.png';
import applemusicWatermark from './applemusic-watermark.png';
import googleplayWatermark from './googleplay-watermark.png';
import youtubeWatermark from './youtube-watermark.png';

import './LargeCarousel.css';
import {numProps} from '../../../Storage/Storage';


class LargeCarousel extends Component {

    musicWatermark = (elem) => {
        let ret = "";
        //console.log('musicWatermark', elem);
        if (elem.type === "link"){
            
            // airbnb
            // apple
            // booking
            // britmovietours
            // googleplay
            // spotify
            // wallmart
            // youtube

            if (elem.subtype === "spotify"){
            ret = (<div className="waterdiv" >
            <img src={spotifyWatermark}    alt="Spotify" className="watermark"/>   
            </div>)
            }
            else if (elem.subtype === "booking"){
                ret = (<div className="waterdiv" >
                <img src={bookingWatermark}    alt="Booking" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "airbnb"){
                ret = (<div className="waterdiv" >
                <img src={airbnbWatermark}    alt="Airbnb" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "britmovietours"){
                ret = (<div className="waterdiv" >
                <img src={britmovieWatermark}    alt="Britmovietours" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "wallmart"){
                ret = (<div className="waterdiv" >
                <img src={wallmartWatermark}    alt="Wallmart" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "apple"){
                ret = (<div className="waterdiv" >
                <img src={applemusicWatermark}    alt="Apple" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "googleplay"){
                ret = (<div className="waterdiv" >
                <img src={googleplayWatermark}    alt="Google Play" className="watermark"/>   
                </div>)
            }
            else if (elem.subtype === "youtube"){
                ret = (<div className="waterdiv" >
                <img src={youtubeWatermark}    alt="YouTube" className="watermark"/>   
                </div>)
            }
        }
        return ret;
    } 


    bubbleShow = (pixelBubble) => {
        let ret = "";
        
        if (this.props.pixelBubble.defined){
            //console.log('pixelBubble',pixelBubble);
            // find center of the picture

            
            let elem  = document.querySelector('#pixel-img');

            if ((elem !== null) && (elem.offsetHeight > 0))
            {
                
                let centerY = elem.offsetHeight/2;
     
                let centerImgY = this.props.pixelBubble.photoY /2;
            
                let kY =  centerY / centerImgY;
				
                let realBubbleTop = elem.offsetHeight/2;
				let realBubbleLeft = elem.offsetWidth/2;
				
				if ((this.props.pixelBubble.bubbleTop !== 0) || (this.props.pixelBubble.bubbleLeft !== 0)){
					realBubbleTop       = this.props.pixelBubble.bubbleTop * kY;
               
					realBubbleLeft      = this.props.pixelBubble.bubbleLeft * kY;
				}

                ret = <div id="bubble-gum" className="bubble-gum" style={{top:realBubbleTop , left :realBubbleLeft
                }}></div>
  
            }
    
            }
 
        return ret;

    }


    showEl = (elem) => {
        let ret = "";
        if (elem.subtype === "youtube"){
                ret = (          <div className="video-carousel-desktop">

<div className="video-border" />
{/*
                <iframe className="stops-video" title="Stop!"
                        src={elem.href}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                />
*/}
<object data={elem.href} className="video-mobile"/>
                </div>)

        }
        else
        {
           // console.log('stopType', this.props.stopType);
        if (this.props.stopType === "pixel"){
            ret =(<div>
            <img id="pixel-img" src={elem.preview} alt=""/>
                {this.bubbleShow()}
            </div>)
        
        }
        else
        {    
         ret = (                        
            <div className="glass1" >
            
             <div  id="pixel-image" style={{height:"480px",backgroundSize: "contain",backgroundRepeat:"no-repeat",
                backgroundImage: "url("+elem.preview+")", backgroundPosition: "center" , zIndex: "9" }}>
                    {this.musicWatermark(elem)}
                 
          
            </div>
            </div>);
        if (elem.href !== ""){
           ret = ( <a href={elem.href} target="_blank" rel="noopener noreferrer">
                            <div className="glass1" >
            
                                <div   id="pixel-image" style={{height:"480px",backgroundSize: "contain",backgroundRepeat:"no-repeat",
                                    backgroundImage: "url("+elem.preview+")", backgroundPosition: "center" , zIndex: "9" }}>
                                        {this.musicWatermark(elem)}
                                       
                                
                                </div>
                            </div>
          </a>);
        }
    }}
        return ret;
    }
    
    render() {
        let sliderCount =numProps(this.props.slider);

        const settings = {
            infiniteLoop: (sliderCount > 1),
            showStatus: false,
            showThumbs: false,
            autoPlay : false,
            stopOnHover: true,
            transitionTime : 720,
            interval: 7000,
  
        };		


        return (

            <div className="large-carousel-stop-desktop">
                
                    <Carousel    {...settings} >  
                    {
                        Object.keys(this.props.slider).map((elem,idx) => (
						<div key={idx} >

                                  {/*  
                                <img src={this.props.slider[idx].photo.url} alt='blur' className="image-blur" />
                                <img src={this.props.slider[idx].photo.url} alt='origin' className="image-origin" />

                                <div>
                                  <div className="blur-div" >
                                    
                                    <img src={this.props.slider[idx].photo.url} alt='origin'   />
						
						
						            </div>


                                    </div>
                                    
                                    <div className="not-blur-div carousel-centered" >

                                    <img src={this.props.slider[idx].photo.url} alt='origin'   className="image-origin"/>

                                    </div>

                                  
                                  

                                  <div className="yellow-rectangle">

                                  <div className="image-test">
                                      <img src={p200x200} alt="picture5" />

                                  </div>

                                  </div>

                                  <div className="blue-rectangle">

                                  <div className="image-test1">
                                      <img src={p350x255} alt="picture2" />

                                  </div>




                                  </div>


                                
                                
                        */}

                        {/*
                        
                        <div className="not-blur-div" >

                                    <img src={this.props.slider[idx].photo.url} alt='origin'   />

                                    </div>

                        */}
                        <div key={idx}  >
                        {this.showEl(this.props.slider[idx])}
                        </div>

                        

                        
						{/*
						
						
						<div  style={{height:"480px",backgroundSize: "fill",      backgroundRepeat: "no-repeat",  backgroundSize: "cover", backgroundImage: "url("+this.props.slider[idx].photo.url+")", backgroundPosition: "center", opacity: "0.1"}	}>
                                   
                             
                            
						</div>	
						zIndex:"-1", opacity: "0.1", filter: "blur(8px)" 
						,filter: "blur(0.1px)",
						
						WebkitFilter: "blur(8px)",
						<img className="blur" src={this.props.slider[idx].photo.url}  alt={this.props.slider[idx].photo.file_name} />

						*/}
						
											
						
                                 
						
						</div>
                        ))
                    }
                   
                    
                </Carousel>
            </div>
        );
    }
}

export default LargeCarousel;