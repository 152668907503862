import React, { Component } from 'react';
//import axios from 'axios';

import './CircleMembers.css';
//import Slider from "react-slick";
// import "../../--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick.css";
// import "../../--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick-theme.css";
// import '../../../Helpers/ui-helpers.js';
import UserAvatar from 'react-user-avatar';

/*
import member1 from './member1.png';
import member2 from './member2.png';
import member3 from './member3.png';
import member4 from './member4.png';
*/

class CircleMembers extends Component {
    constructor(props) {
        super(props);
        
        //this.next = this.next.bind(this);
        //this.previous = this.previous.bind(this);

        console.log("PROPS MEM: ", this.props);

         this.state = {
             members: []
         };
    }

    /*
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
    */

    render() {
        /*
        var settings = {
            infinite: true,
            slidesToShow: 5,
            swipeToSlide: true
        };
        */

        const { members } = this.props;

        const allUsers = [].concat.apply([], members);
        const mergedUsers = [].concat.apply([], allUsers).slice(0, 3);
        const allUsersCount = allUsers.length;

        const userCircles = []

        mergedUsers.forEach((user, index) => {
            let name = user.first_name + " " + user.last_name;

            if (index <= 3) {
                userCircles.push((
                    <div key={index} style={{zIndex: index}}>
                        <UserAvatar size="56" name={name} src={user.img} />
                        {/* <img className="member-circle" letters="PP" src={user.img} alt={user.nickname} onClick={() => console.log("User cliked: " + user.nickname)}/> */}
                    </div>
                ))
            }
        });

        if (allUsersCount > 3) {
            userCircles.push((
                <div key={3} style={{zIndex: 3}}>
                    <div className="member-circle last">+{allUsersCount-3}</div>
                </div>
            ));
        }

        //{/* <Slider className="slider" ref={c => (this.slider = c)} {...settings}> */}
        //{/* </Slider> */}

        return (
            <div className="members-desktop">
                <div className="members-container">
                    {userCircles}
                </div>
            </div>
        )
    }
}

export default CircleMembers;