import React, { Component } from 'react';
import './YelpsNearby.css';
import Slider from "react-slick";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick.css";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
import yelpLogo from './yelp-logo.png'
import stars from './stars.png'

class YelpsNearby extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
	
	elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.yelpsCount === 1){
			ret = nameClass + "-left";
		}
		if (this.props.yelpsCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
	}
	

    getYelpImg = (elem) =>{
        let url = "";
        
        if (elem.elem[1].content.yelp.url !== undefined){
            url = elem.elem[1].content.yelp.url;
        }
        else
        {
            url = elem.elem[1].expandedUrl;
        }
        //console.log('Yelp elem',elem.elem[1]);
        //console.log('Yelp url',elem.elem[1].content.yelp.url);
		let yelpPreview = elem.elem[1].preview;
		//console.log('yelpPreview',yelpPreview);
		if (!yelpPreview ){
			yelpPreview = "/img/yelp-empty.png";
		}	
			

        return (
            <div>
                <a href={url} target="_blank" rel="noopener noreferrer">
                <img className={this.elem2Left("yelp-image")} src={yelpPreview} alt={elem.elem[1].name}  />
                <img className={this.elem2Left("yelp-logo")} src={yelpLogo} alt={yelpLogo} />
                </a>
                <div className={this.elem2Left("yelp-slide-text")}>
                            <div className="yelp-slide-title">
                                {elem.elem[1].name}
                            </div>
                            <div className="rating-info">
                            <table>
                            <thead></thead>
                                <tbody>
                                <tr>
                                    <td className="star-rating">
                                    {elem.elem[1].externalRating}
                                    </td>
                                    <td>
                                       <img className="stars" src={stars} alt={stars} />
                                    </td>
                                    <td className="num-ratings">
                                        (111)
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div className="yelp-info">
                                $$$$ •  {elem.elem[1].address.street}
                            </div>
                        </div>
            </div>
        )

    }


    prevnext = (count) =>{
        if (this.isDebug){
            console.log('count',count);
        }
        if (count){
        return (
            <div>
            <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
            <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
            </div>
        )
        }
        else
        {
            return(
            <div>

            </div>
            )
        }
    }

    
    render() {
        var settings = {
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true
        };

        if (this.props.yelpsCount > 0){
        return (
            <div className="yelps-nearby-mobile">
                <div className="yelps-navigation">
                    <span className="yelps-nearby-title">
                        Yelp nearby
                    </span>
                    {/*
                        
                        this.prevnext(this.props.yelpsCount > 3)
                      
                        
                   */}
 
                </div>
                <Slider className="slider" ref={c => (this.slider = c)} {...settings}>
      
                { Object.entries(this.props.yelps).map((elem, idx) => (
                    <div key={idx}>
                        <div className="yelp-container">
                       {
                           this.getYelpImg({elem})
                       }
                                               
                        </div>
                    </div>
                                           ))
                }
                                           
                    

                </Slider>
            </div>
        )
    }
    else
    {
        return (<div></div>)
    }
    }

}

export default YelpsNearby;