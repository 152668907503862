import ScrollToTop from "../../../ScrollToTop";
import Navbar from '../../../SharedComponents/Desktop/Navbar/Navbar.js';
import AppLinks from '../../../SharedComponents/Desktop/AppLinks/AppLinks.js';
import Meta from  '../../../Meta/Meta.js';
import ArtBoard from '../ArtBoard/ArtBoard.js'
import Map from '../Map/Map.js'
import LocationInfo from '../LocationInfo/LocationInfo.js'
import Comments from '../Comments/Comments.js';
import Weather from '../../../SharedComponents/Desktop/WeatherDesktop/Weather.js'
import Attachments from '../../../SharedComponents/Desktop/AttachmentsDesktop/Attachments.js'
import StopsNearby from '../../../SharedComponents/Desktop/StopsNearbyDesktop/StopsNearby.js'
//import WikiArticlesNearby from '../../../SharedComponents/Desktop/WikiArticlesNearbyDesktop/WikiArticlesNearby.js';
import WikiStopsNearby from '../../../SharedComponents/Desktop/WikiStopsNearbyDesktop/WikiStopsNearby.js';
import YelpsNearby from '../../../SharedComponents/Desktop/YelpsNearbyDesktop/YelpsNearby.js'
import GoogleNearby from '../../../SharedComponents/Desktop/GoogleArticlesNearbyDesktop/GoogleArticlesNearby.js'
import Features from '../../../SharedComponents/Desktop/FeaturesDesktop/Features.js'
import Footer from '../../../SharedComponents/Desktop/FooterDesktop/Footer.js'
import React from 'react';
import './AppStopDesktop.css';
//import loadingGif from '../../../LoadGifs/ttv_loading.gif';
import {getStops} from '../../../Storage/dataBroker.js';
import {AppEnv} from '../../../Storage/Environment.js';
import MeetupsNearby from "../../../SharedComponents/Desktop/MeetupsNearbyDesktop/MeetupsNearby";


class  AppStopDesktop extends React.Component {
	constructor(props){
		super(props);
		
		this.state ={
				isLoaded : false,

				// <Navbar />
				firstName : "",
				lastName: "",
				img: "",
				unreadMsgCount : 0,
				fullName: "",

        // <PhotoInfo />
        id: 0,
				slider: {},
				title: "",
				description: "",
				month: "",
				day : "",
				year: "",
				hashtags: {}, 
				fullAddress : "",
			   


				//  <LocationInfo />
				altitude: 0,
				latitude: 0,
				longitude: 0,

				hereMapsURI : "",
				imageMapURI : "",
				
				
				// attachements
				attachements    : {},
				attsCount:0,
				


				//weather
				current_temp_C  : "",
				current_temp_F  :"",
				condition : "",
        weatherIconUrl : "",
        weathercode: 0,


				// within
				within: {},
				withinCount  : 0,

				// yelps
				yelps : {},
				yelpsCount :0,

				// wiki
				wiki:{},
        wikiCount : 0,

        google: {},
        googleCount : 0,

        wikiStop:{},
        wikiStopCount :0,
        

        // facebook open graph       
      og : {},

      env: AppEnv(),
	  
	  stopType: "",
  
    
      comments: {},
      comCount: 0,
	  hostname: window.location.hostname,

      pixelBubble : {},

      business : {
        email: "",
        phone: ",",
        last_name: "",
        first_name: "",
        hours: {}
   }                    
  
    


    };
    

		getStops(this.onComplete,this.onComplete1,this.onComplete2,this.onComplete3,this.onComplete4,this.onComplete5,this.onComplete6);

    }


    componentDidMount(){
      this.setState({env: AppEnv()});
    }
	// ===============================================

    onComplete6 = (comments) =>{ 
      this.setState({
        comments: comments.comments,
        comCount: comments.comCount

      });
    }

    // ===============================================
		
    onComplete5 = (wiki) =>{ 
      this.setState({
        wiki:wiki.wiki,
        wikiCount : wiki.wikiCount
      });
    }
          
    onComplete4 = (within,yelps, google,wikiStop) => { 
      // When the code completes, do this
      //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
            within: within.within,
            withinCount: within.withinCount,
            yelps : yelps.yelps,
            yelpsCount :yelps.yelpsCount,
            google: google.google,
            googleCount : google.googleCount,
            wikiStop: wikiStop.wiki,
            wikiStopCount : wikiStop.wikiCount
      });
    }
          
        


    onComplete3 = (weather) => { 
      // When the code completes, do this    
      //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
          current_temp_C  : weather.current_temp_C,
          current_temp_F  : weather.current_temp_F,
          condition : weather.condition,
          weatherIconUrl : weather.weatherIconUrl,
          weathercode: weather.code
      });
    }
          

    onComplete2 = (user) => { 
      // When the code completes, do this
      //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
          firstName :user.firstName,
          lastName : user.lastName,
          img : user.img,
          unreadMsgCount: user.unreadMsgCount,
          fullName : user.fullName,
      });
    }
          

    onComplete1 = (photoinfo,locationinfo,attachements,og) => { 
      // When the code completes, do this
      //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
          isLoaded: true,
          id : photoinfo.id,
          stopType: photoinfo.type,							  
          slider : photoinfo.slider,
          title: photoinfo.title,
          description: photoinfo.description,
          month : photoinfo.month,
          day: photoinfo.day,
          year : photoinfo.year,
          hashtags : photoinfo.hashtags,
          pixelBubble: photoinfo.pixelBubble,
          business : photoinfo.business,
          fullAddress : locationinfo.fullAddress,
          altitude: locationinfo.altitude,
          latitude: locationinfo.latitude,
          longitude: locationinfo.longitude,
          hereMapsURI : locationinfo.hereMapsURI,
          imageMapURI :locationinfo.imageMapURI,
          attachements    : attachements.attachements,
          attsCount : attachements.attsCount,
          // facebook open graph
          og : og
      });
    }
          
          
	
	onComplete = (isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps) =>{ 
    // When the code completes, do this
    //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
    this.setState({
        isLoaded : true, 
        firstName :user.firstName,
        lastName : user.lastName,
        img : user.img,
        unreadMsgCount: user.unreadMsgCount,
        fullName : user.fullName,
        slider : photoinfo.slider,
        title: photoinfo.title,
        description: photoinfo.description,
        month : photoinfo.month,
        day: photoinfo.day,
        year : photoinfo.year,
        hashtags : photoinfo.hashtags,
        fullAddress : locationinfo.fullAddress,
        altitude: locationinfo.altitude,
        latitude: locationinfo.latitude,
        longitude: locationinfo.longitude,
        hereMapsURI : locationinfo.hereMapsURI,
        imageMapURI :locationinfo.imageMapURI,
        attachements    : attachements.attachements,
        attsCount : attachements.attsCount,
        current_temp_C  : weather.current_temp_C,
        current_temp_F  : weather.current_temp_F,
        condition : weather.condition,
        weatherIconUrl : weather.weatherIconUrl,
        within: within.within,
        withinCount  : within.withinCount,
        yelps : yelps.yelps,
        yelpsCount :yelps.yelpsCount,
        wiki:wiki.wiki,
        wikiCount : wiki.wikiCount
    });
  } 
  
  render() {

      const {isLoaded} = this.state;

    if (isLoaded) {
      return (
        <div className="app-stop-desktop">
          <div className="wrapper">
            <div className="show-background-image">
              <div className="top-components">
                <Meta og={this.state.og} />
                <ScrollToTop  wiki={this.state.wiki}>
                <Navbar fullName={this.state.fullName} img={this.state.img} unreadMsgCount={this.state.unreadMsgCount}/>
                  <div className="outer">
                    <div className="inner">
                      <AppLinks />
                        <div className="bottom-components">

                          <ArtBoard   slider={this.state.slider} month={this.state.month} day={this.state.day} 
                                      year={this.state.year} title={this.state.title} 
                                      description={this.state.description} 
                                      hashtags={this.state.hashtags}
                                      pixelBubble={this.state.pixelBubble}
                                      stopType={this.state.stopType} business={this.state.business}/>
                          <Map hereMapsURI={this.state.hereMapsURI} imageMapURI={this.state.imageMapURI} title={this.state.title} />
                          <LocationInfo altitude={this.state.altitude} latitude={this.state.latitude} longitude={this.state.longitude}
                                                  fullAddress={this.state.fullAddress} hereMapsURI={this.state.hereMapsURI}/>
                          <Weather  current_temp_C={this.state.current_temp_C}    
                                            current_temp_F={this.state.current_temp_F}   
                                            condition={this.state.condition}  
                                            weatherIconUrl={this.state.weatherIconUrl} weatherCode={this.state.weathercode}/>
                          <Attachments attachements={this.state.attachements} attsCount={this.state.attsCount}/>
                          <StopsNearby within={this.state.within}  sWCount={this.state.withinCount} mobile={this.state.env.mobile}  imgUser={this.state.img}/>
                          <GoogleNearby google={this.state.google} googleCount={this.state.googleCount} />
                          <WikiStopsNearby wiki={this.state.wikiStop} wikiCount={this.state.wikiStopCount}/>

                          {/*
                          <WikiArticlesNearby wikiStopCount={this.state.wikiStopCount} wiki={this.state.wiki} wikiCount={this.state.wikiCount}/>
                          */}
                          
                          <YelpsNearby  yelps={this.state.yelps} yelpsCount={this.state.yelpsCount} />
                                    {/* Auth for Meetups need to be on the server */}
                                    {/* <MeetupsNearby location={{lat: this.state.latitude, lng: this.state.longitude}} /> */}

                          <Comments stopType={this.state.stopType} comCount={this.state.comCount}  comments={this.state.comments} />
                          <Features />
                          <Footer />
                      </div>
                  </div>
                </div>
              </ScrollToTop>
            </div>
          </div>
        </div>
      </div>
      )
    } else {
      return (
        <div className="loader-overlay">
          <div class="lds-ripple"><div></div><div></div></div>
        </div>
      )
    }
  }
}

export default AppStopDesktop;
