import axios from 'axios';
import React, { Component } from 'react';
import './MeetupsNearby.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
import meetupLogo from './meetup-logo.png';
// import MeetupsAPI from '../../../api/meetups_api';

class MeetupsNearby extends Component {
    constructor(props) {
        super(props);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        
        this.state = {
            meetups: [],
            auth_code: '',
            access_token: null
        }
    }

    state = {
        meetups: [],
        auth_code: '',
        access_token: null
    };

    componentDidMount() {

        if (this.state.auth_code === '' || this.state.access_token.access_token === '') {
            console.log('Need to authorize!');
           this.authorize();
        } else {
            console.log('Authorized, loading data!!!!!!');

            if (this.state.auth_code === '') {
                this.getToken();
            } else {
                if (this.state.access_token.access_token === '') {
                    this.getToken();
                } else {
                    this.loadMeetups();
                }
            }
        }

        // this.testTokenLoading()
    }

    // testTokenLoading() {
    //     const cunsumer_key = '7pc5bb0ir6qbbft8sctrj558vm'
    //     const redirect_url = 'https://l.stops.com'

    //     let url = 'https://secure.meetup.com/oauth2/authorize?client_id=' + cunsumer_key + '&response_type=token&redirect_uri=' + redirect_url

    //     axios.get(url)
    //     .then(response => response.data)
    //     .then((data) => {
    //         console.log("AUTH RESPONSE: " + data);
    //         //console.log("AUTH RESPONSE: " + JSON.stringify(response.data.code))
    //     })
    //     .catch((error) => {
    //         console.log("Error with getting authorization code: " + error)
    //         this.setState({ auth_code: '' })
    //     });
    // }

    // dateObj - object of Date, formatType - date format type, eg. - yyyy-MM-dd
    date2str(dateObj, formatType) {
        var z = {
            M: dateObj.getMonth() + 1,
            d: dateObj.getDate(),
            h: dateObj.getHours(),
            m: dateObj.getMinutes(),
            s: dateObj.getSeconds()
        };
        formatType = formatType.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
            return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
        });

        return formatType.replace(/(y+)/g, function(v) {
            return dateObj.getFullYear().toString().slice(-v.length)
        });
    }

    
// 1) Requesting Authorization and Receiving Access Token
    authorize() {
        console.log("authorize");

        const cunsumer_key = '7pc5bb0ir6qbbft8sctrj558vm'
        const redirect_url = 'https://l.stops.com'
        let baseURL = 'https://secure.meetup.com/oauth2/authorize?client_id=' + cunsumer_key + '&redirect_uri=' + redirect_url + '&response_type=anonymous_code'

        let headers = { headers: {'Accept': 'application/json'} }

        axios.get(baseURL, headers)
        .then(response => response.data.code)
        .then((code) => {
            //alert("AUTH RESPONSE: " + code);
            this.setState({ auth_code: code })
            this.getToken()
            //console.log("AUTH RESPONSE: " + JSON.stringify(response.data.code))
        })
        .catch((error) => {
            console.log("Error with getting authorization code: " + error)
            this.setState({ auth_code: '' })
        });

        //this.getToken();
        //this.loadMeetups();
    }

// 2) Get Token for requests
    getToken() { // Check if is invalid (maybe old one still valid)
        console.log("getToken");

        const cunsumer_key = '7pc5bb0ir6qbbft8sctrj558vm'
        const redirect_url = 'https://l.stops.com'
        const cunsumer_secret = 'shncv3g7m9ot5dsodls0f681fu'

        const auth_code = this.state.auth_code;

        let baseURL = "https://secure.meetup.com/oauth2/access?client_id=" + cunsumer_key + "&client_secret=" + cunsumer_secret + "&grant_type=anonymous_code&redirect_uri=" + redirect_url + "&code=" + auth_code;
        
        if (auth_code !== '') { // If we have a auth code
            axios.post(baseURL)
            .then(response => response.data)
            .then(data => {
                console.log("Get token data: " + data.access_token);
                this.setState({ access_token: data.access_token })
                this.loadMeetups();
            })
            .catch(error => {
                console.log("error: " + error);
                this.setState({ access_token: null })
            });
        } else {
            console.log("no auth code!");
        }
    }

   // Using axios
    loadMeetups() {
        console.log("Loading meetups");

       let baseURL = 'https://api.meetup.com/';
       let postfix = 'find/upcoming_events'
       let url = baseURL + postfix;

       let maxEndDate = new Date(); 
       maxEndDate.setDate(new Date().getDate() + 7); // Today + 7 days
   
       let formattedDate = this.date2str(maxEndDate, "yyyy-MM-dd") + "T00:00:00" // yyyy-MM-dd
       console.log("Loading meetups formattedDate: " + formattedDate)
       let location = this.props.location;
       let fields = "featured_photo,fee,group_key_photo,event_hosts";
        
       let authToken = this.state.access_token;

       if (authToken === '') {
            console.log('Error!!! auth token is empty!!!');
           return;
       } else {
           console.log('auth token:' + authToken);
       }

        // const headers = {
        //     headers: {Authorization: `Bearer ${authToken}`}
        //     {"Content-Type": "application/json",
        //    "Authorization": "Bearer e2829ef1e511560fbba3e625425d9cd2226ba129161cfcc350e987669e484044"
        //   }
        // };

        const bodyParameters = {
            lat: location.lat,
            lng: location.lng,
            end_date_range: formattedDate,
            fields: fields
        };
        //    radius: '',
        //    page: '',

        let headers = { 
            headers: {'Authorization': `Bearer ${authToken}`},
            params: bodyParameters
        };

        //bodyParameters,

    // We're using axios instead of Fetch
       axios
       // The API we're requesting data from
       .get(url, headers)
       .then(response => response.data.events)
       .then(events => {
           //let events = data.events;
           // handle success
           console.log("RESPONSE events: " + events);
           this.setState({ meetups: events })
       })
        .catch((error) => {
            console.log("catch error: " + error);
        });

    //    .then(response => {
    //        console.log("MEETUPS: " + response.data.result);
    //    });

        // response.data.results.map(user => ({
        //   name: `${user.name.first} ${user.name.last}`,
        //   username: `${user.login.username}`,
        //   email: `${user.email}`,
        //   image: `${user.picture.thumbnail}`
        // }))

      // Let's make sure to change the loading state to display the data
      /*
    .then(users => {
        this.setState({
            users,
            isLoading: false
        });
    })
      // We can still use the `.catch()` method since axios is promise-based
      .catch(error => this.setState({ error, isLoading: false }));
      */
      
       /*
       .get(url, params)
        .then(response => response.json())
        .then(data => this.setState({ meetups: data.events }))
        .catch(error => this.setState({ meetups: [] }));
        */
   }
    
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }  
	
    prevnext = (count) =>{
        if (count){
            return (
                <div>
                <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
                <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
                </div>
            )
        }
        else
        {
            return(
                <div></div>
            )
        }
    }

    getMeetupURL = (element) =>{
        console.log('Meetup url: ', element.link);
        let url = element.link;
        
        return url
    }

    getMeetupImage = (element) => {
        // REMOVE AFTER TESTS!

        console.log("************START************")
        console.log("ELEMENTS: ")
        for (let [key, value] of Object.entries(element)) {
            console.log(`${key}: ${value}`);
        }
        console.log("************END************")

       let keyPhoto = element.group.key_photo;
       console.log("keyPhoto: " + keyPhoto);

       let photoURL = ''

       if (keyPhoto !== undefined && keyPhoto.photo_link !== undefined) {
           photoURL = keyPhoto.photo_link;
       }

        if (photoURL === '') {
            return(
                <div>
                    <img className={this.elem2Left("meetup-image")} src="/img/meetup_placeholder.png" alt={element.photoURL}/>
                    {/* <img className={this.elem2Left("meetup-logo")} src={meetupLogo} alt={meetupLogo} /> */}
                </div>
            )
        }
        else
        {
            return(
                <div>
                    <img className={this.elem2Left("meetup-image")} src={photoURL} alt={element.photoURL}/>
                    <img className={this.elem2Left("meetup-logo")} src={meetupLogo} alt={meetupLogo} />
                </div>
            )
        }
    }

    elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.state.meetups.length === 1){
			ret = nameClass + "-left";
		}
		if (this.state.meetups.length === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
    }	
    
    render() {
        var settings = {
            infinite: false,
            slidesToShow: 3,
            arrows: true
        };

        if (this.state.meetups.length > 0) {
            return (
                <div className="meetups-nearby-desktop">
                    <div className="meetup-navigation">
                        <span className="meetup-title">
                        Meetups nearby
                        </span>
                        {
                            this.prevnext(this.state.meetups.length > 3)       
                        }
                        </div>
                    <Slider ref={c => (this.slider = c)} className="slider" {...settings} >
                            {
                                Object.entries(this.state.meetups).map((elem,idx) => (
                                    <div key={idx}>
                                        <div className="meetup-container">
                                            <a href={this.getMeetupURL(elem[1])} target="_blank" rel="noopener noreferrer">
                                                {
                                                    this.getMeetupImage(elem[1])
                                                }
                                            </a>
                                        </div>
                                        <div className={this.elem2Left("meetup-slide-text")}>
                                            <div className="meetup-slide-title">
                                            {elem[1].name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) 
                        }
                
                    </Slider>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
}

export default MeetupsNearby;