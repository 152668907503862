import React, { Component } from 'react';
import './BottomLinksWidget.css';
import stopsBlueLogoImage from './stopsBlueLogoImage.png'; // TODO

class BottomLinksWidget extends Component {
    constructor(props) {
        super(props);
        
        //console.log("PROPS: ", this.props);
        this.state = {};
    }

    render() {
        // const { title, description } = this.props;

        return (
            <div className="bottom-links-widget-mobile">
                <div className="container">
                    <div className="link-container">
                        <a href="https://www.stops.com">
                            <img className="logo-blue" src={stopsBlueLogoImage} alt={stopsBlueLogoImage}></img>
                        </a>
                    </div>
                    <div className="link-container">
                        <a href="https://help.stops.com">
                            HELP
                        </a>
                    </div>
                    <div className="link-container">
                        <a href="https://blog.stops.com">
                            BLOG
                        </a>
                    </div>
                    <div className="link-container">
                        <a href="mailto:team@stops.com">
                            CONTACT
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomLinksWidget;