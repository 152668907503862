import React, { Component } from 'react';
import './DownloadBanner.css';
import xButton from './x-button.svg'
import stopsIcon from './stops-banner-icon.png'
import orangeStars from './orange-stars.svg'
import {AppEnv} from '../../../Storage/Environment.js';

class DownloadBanner extends Component {
    constructor(props) {
        super(props);
		
        this.state = {
            isVisible: true,
			env:AppEnv()
        };
		
		
    };

    hideBanner() {
        this.setState({isVisible: !this.state.isVisible})
    }

    render() {

        let bannerClass = this.state.isVisible ? "download-banner" : "banner-hidden";

        return (
            <div className={bannerClass} style={this.style}>
                <div className="content-left">
                <img className="x-button" src={xButton} alt={xButton} onClick={this.hideBanner.bind(this)} />
                <img className="stops-icon" src={stopsIcon} alt={stopsIcon} />
                    <table className="banner-table">
					<tbody>
                    <tr>
                        <td className="stops-app-text">
                            Stops App
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img className="orange-stars" src={orangeStars} alt={orangeStars} />
                        </td>
                    </tr>
                    <tr>
                        <td className="stops-ltd-text">
                            Stops Ltd.
                        </td>
                    </tr>
					</tbody>
                </table>
            </div>
                <div className="content-right">
                    <a href={this.state.env.downloadHref}>
                    <button onClick={() => console.log(navigator)} className="download-button">
                         Download
                     </button>
                    </a>
                </div>
            </div>
        );
    }
}

export default DownloadBanner;