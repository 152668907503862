import React from 'react';
import './Navbar.css';

import stopsLogo from './stops-logo.png'
import profilePic from './profile-pic.png'
import avatarPlaceholder from './userAvatarPlaceholder.png';

class Navbar extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        user: null
      };
  
    }

    render() {
        const {user} = this.props;
        let name = user.first_name + " " + user.last_name;
        let avatar = user.picture ?? avatarPlaceholder;

        return (
            <div className="navbar-pixel-desktop">
                <div className="navbar-container">
                    <a href="https://www.stops.com">
                        <img className="stops-logo" src={stopsLogo} alt={stopsLogo} />
                    </a>
                    <img className="profile-pic" src={avatar} alt={avatar} />
                    <div className="navbar-text">
                        <div className="shared-by">Created by:</div>
                        <div className="name"><strong>{name}</strong></div>
                    </div>
                </div>
            </div>
        );
    }
}
/*
function Navbar() {

    const {user} = this.state;
    let name = user.first_name + " " + user.last_name;

    return (
        <div className="navbar-pixel-desktop">
            <div className="navbar-container">
                <a href="https://www.stops.com">
                    <img className="stops-logo" src={stopsLogo} alt={stopsLogo} />
                </a>
                <img className="profile-pic" src={profilePic} alt={profilePic} />
                <div className="navbar-text">
                    <div className="shared-by">Shared by:</div>
                    <div className="name"><strong>{name}</strong></div>
                </div>
            </div>
        </div>
    );
}
*/

export default Navbar;