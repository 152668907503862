import DownloadBanner from '../../../SharedComponents/Mobile/DownloadBanner/DownloadBanner.js'
import Navbar from '../../../SharedComponents/Mobile/Navbar/Navbar.js';
import PhotoInfo from '../PhotoInfo/PhotoInfo.js';
import LocationInfo from '../LocationInfo/LocationInfo.js'
import Comments from '../Comments/Comments.js';
import Weather from '../../../SharedComponents/Mobile/WeatherMobile/Weather.js'
import Attachments from '../../../SharedComponents/Mobile/AttachmentsMobile/Attachments.js'
import StopsNearby from '../../../SharedComponents/Mobile/StopsNearbyMobile/StopsNearby.js'
//import WikiArticlesNearby from '../../../SharedComponents/Mobile/WikiArticlesNearbyMobile/WikiArticlesNearby';
import WikiStopsNearby from '../../../SharedComponents/Mobile/WikiStopsNearbyDesktop/WikiStopsNearby.js';
import GoogleNearby from '../../../SharedComponents/Mobile/GoogleArticlesNearbyMobile/GoogleArticlesNearby'
import YelpsNearby from '../../../SharedComponents/Mobile/YelpsNearbyMobile/YelpsNearby.js'
import MeetupsNearby from '../../../SharedComponents/Mobile/MeetupsNearbyMobile/MeetupsNearby.js'
import Features from '../../../SharedComponents/Mobile/FeaturesMobile/Features.js'
import Footer from '../../../SharedComponents/Mobile/FooterMobile/Footer.js'
import Meta from  '../../../Meta/Meta.js';
import React from 'react';
import './AppStopMobile.css';
//import {getStopsHeader,getStopIdString,getHereMapsURI,getHereMapsMobile,getWeateherURI,getJsonHeader,numProps,getWithinURL,getWikiURI} from '../../../Storage/Storage';
//import loadingGif from '../../../LoadGifs/ttv_loading.gif';
import {getStops} from '../../../Storage/dataBroker.js';
//import {getStopIdString} from '../../../Storage/Storage.js';
import {AppEnv} from '../../../Storage/Environment.js';

class AppStopMobile extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
				isLoaded : false,

				// <Navbar />
				firstName : "",
				lastName: "",
				img: "",
				unreadMsgCount : 0,
				fullName: "",

        // <PhotoInfo />
        id: 0,
				slider: {},
				title: "",
				description: "",
				month: "",
				day : "",
				year: "",
				hashtags: {}, 
				fullAddress : "",
			   


				//  <LocationInfo />
				altitude: 0,
				latitude: 0,
				longitude: 0,

				hereMapsURI : "",
				imageMapURI : "",
				
				
				// attachements
				attachements    : {},
				attsCount:0,


				//weather
				current_temp_C  : "",
				current_temp_F  :"",
				condition : "",
        weatherIconUrl : "",
        weathercode: 0,


				// within
				within: {},
				withinCount  : 0,

				// yelps
				yelps : {},
				yelpsCount :0,

				// wiki
				wiki:{},
        wikiCount : 0,
        

        google: {},
        googleCount : 0,

        wikiStop:{},
        wikiStopCount :0,
        
        
        
        // facebook open graph       
      og : {},

      env: AppEnv(),
	  
	    stopType: "",
  
    
      comments: {},
      comCount: 0,
 	    hostname: window.location.hostname,


      pixelBubble : {},

      business : {
        email: "",
        phone: ",",
        last_name: "",
        first_name: "",
        hours: {}
      }

    };
    
		  getStops(this.onComplete,this.onComplete1,this.onComplete2,this.onComplete3,this.onComplete4,this.onComplete5,this.onComplete6);
    }


    componentDidMount(){
      this.setState({env: AppEnv()});
    }
	
	// ===============================================

    onComplete6 = (comments) =>{ 
      this.setState({
        comments: comments.comments,
        comCount: comments.comCount
      });
    }

    // ===============================================
	     
    onComplete5 = (wiki) =>{ 
      this.setState({
          wiki:wiki.wiki,
          wikiCount : wiki.wikiCount
      });
    }
      
    
onComplete4 = (within,yelps,google,wikiStop) => {
  // When the code completes, do this
  //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
          within: within.within,
          withinCount  : within.withinCount,
          yelps : yelps.yelps,
          yelpsCount :yelps.yelpsCount,
          google: google.google,
          googleCount : google.googleCount,
          wikiStop: wikiStop.wiki,
          wikiStopCount : wikiStop.wikiCount
      });
    }

onComplete3 = (weather) =>{ 
  // When the code completes, do this
  //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
          current_temp_C  : weather.current_temp_C,
          current_temp_F  : weather.current_temp_F,
          condition : weather.condition,
          weatherIconUrl : weather.weatherIconUrl,
          weathercode: weather.code
      });
    }
      
onComplete2 = (user) => { 
  // When the code completes, do this
  //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
        firstName :user.firstName,
        lastName : user.lastName,
        img : user.img,
        unreadMsgCount: user.unreadMsgCount,
        fullName : user.fullName
      });
  }

onComplete1 = (photoinfo,locationinfo,attachements,og) => {
  // When the code completes, do this
  //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);
      this.setState({
          isLoaded: true,
          id : photoinfo.id,
          stopType: photoinfo.type,
          slider : photoinfo.slider,
          title: photoinfo.title,
          description: photoinfo.description,
          month : photoinfo.month,
          day: photoinfo.day,
          year : photoinfo.year,
          hashtags : photoinfo.hashtags,
          pixelBubble: photoinfo.pixelBubble,
          business : photoinfo.business,
          fullAddress : locationinfo.fullAddress,
          altitude: locationinfo.altitude,
          latitude: locationinfo.latitude,
          longitude: locationinfo.longitude,
          hereMapsURI : locationinfo.hereMapsURI,
          imageMapURI :locationinfo.imageMapURI,
          attachements    : attachements.attachements,
          attsCount : attachements.attsCount,
          og: og
      });
    }

onComplete = (isLoad, user, photoinfo, locationinfo, attachements, weather, within, wiki, yelps) => {
  // When the code completes, do this
  //console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);

    this.setState({
        isLoaded: true,
        firstName :user.firstName,
        lastName : user.lastName,
        img : user.img,
        unreadMsgCount: user.unreadMsgCount,
        fullName : user.fullName,
        slider : photoinfo.slider,
        title: photoinfo.title,
        description: photoinfo.description,
        month : photoinfo.month,
        day: photoinfo.day,
        year : photoinfo.year,
        hashtags : photoinfo.hashtags,
        fullAddress : locationinfo.fullAddress,
        altitude: locationinfo.altitude,
        latitude: locationinfo.latitude,
        longitude: locationinfo.longitude,
        hereMapsURI : locationinfo.hereMapsURI,
        imageMapURI :locationinfo.imageMapURI,
        attachements    : attachements.attachements,
        attsCount : attachements.attsCount,
        current_temp_C  : weather.current_temp_C,
        current_temp_F  : weather.current_temp_F,
        condition : weather.condition,
        weatherIconUrl : weather.weatherIconUrl,
        within: within.within,
        withinCount  : within.withinCount,
        yelps : yelps.yelps,
        yelpsCount :yelps.yelpsCount,
        wiki:wiki.wiki,
        wikiCount : wiki.wikiCount
    });
}
      
  render() {
  
    const { isLoaded } = this.state;

	//if (this.state.hostname !== "localhost"){  
    //let appStop = "stopsapp://stop/"+getStopIdString();
    
    // turn off yet
    /*
		setTimeout(function () {
				window.location.replace(appStop); }
    , 2000);
    */
		 
		//console.log('AppStopMobile', appStop);
		//let operationSystem = this.state.env.os;

		//console.log('operationSystem',operationSystem);
		//window.location = appStop;
    //window.open(appStop,'_blank');
    
  //}
    if (isLoaded) {
      return (
        <div>
          <div className="app-stop-mobile">
            <div className="wrapper">
              <Meta og={this.state.og} />
              <DownloadBanner />
              <Navbar fullName={this.state.fullName} img={this.state.img} unreadMsgCount={this.state.unreadMsgCount}/>
              <PhotoInfo slider={this.state.slider} month={this.state.month} day={this.state.day} 
                                  year={this.state.year} title={this.state.title} 
                                  description={this.state.description} 
                                  hashtags={this.state.hashtags}
                                  pixelBubble={this.state.pixelBubble} 
                                  stopType={this.state.stopType} 
                                  business={this.state.business}/>

              <LocationInfo altitude={this.state.altitude} latitude={this.state.latitude} longitude={this.state.longitude}
                                      hereMapsURI={this.state.hereMapsURI} imageMapURI={this.state.imageMapURI}
                                      fullAddress={this.state.fullAddress}/>
                                      
              <Weather  current_temp_C={this.state.current_temp_C}    
                                current_temp_F={this.state.current_temp_F}   
                                condition={this.state.condition}  
                                weatherIconUrl={this.state.weatherIconUrl} weatherCode={this.state.weathercode}/>

              <Attachments attachements={this.state.attachements} attsCount={this.state.attsCount}/>         
              <StopsNearby  within={this.state.within}  sWCount={this.state.withinCount} mobile={this.state.env.mobile} imgUser={this.state.img}/>
              <GoogleNearby google={this.state.google} googleCount={this.state.googleCount} />		  
          {/* 
              <WikiArticlesNearby wikiStopCount={this.state.wikiStopCount} wiki={this.state.wiki} wikiCount={this.state.wikiCount}/>
          */} 
              <WikiStopsNearby wiki={this.state.wikiStop} wikiCount={this.state.wikiStopCount} />		  
              <YelpsNearby yelps={this.state.yelps} yelpsCount={this.state.yelpsCount} />
              {/* Auth for Meetups need to be on the server */}
              {/* <MeetupsNearby location={ {lat: this.state.latitude, lng: this.state.longitude} } /> */}
              <Comments stopType={this.state.stopType}  comCount={this.state.comCount}  comments={this.state.comments} />
              <Features />
              <Footer />
            </div>
          </div>
        </div>
      )
    } else {
      return (
          <div className="loader-overlay">
            <div class="lds-ripple"><div></div><div></div></div>
          </div>
      )
    }
  }
}

export default AppStopMobile;
