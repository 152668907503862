import React, { Component } from 'react';
import './WikiStopsNearby.css';
import Slider from "react-slick";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick.css";
// import "../../../Cube/--CubeMobileOld/Members/node_modules/slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';
//import wikiImage from './wiki-image.jpg';
import wikiLogo from './wiki-logo.png';
//import {getStopsHeader,getStopIdString,getWikiURL,getJsonHeader} from '../../../Storage/Storage';
//import {getDescription} from '../../../Storage/Storage.js';

class WikiStopsNearby extends Component {
    constructor(props) {
        super(props);

		this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

   next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }  
	
    prevnext = (count) =>{
        if (count){
            return (
                // <div>
                // <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
                // <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
                // </div>
                <div></div>
            )
        }
        else
        {
            return(
                <div></div>
            )
        }
    }

    getWikiImage = (elem) =>{
        //console.log('getStopWikiImage:',elem);
        if (!elem.preview){
            return(
            <div>
                <img className={this.elem2Left("wiki-image")} src="/img/wikistop-empty.png" alt={elem.name}/>
                <img className={this.elem2Left("wiki-logo")} src={wikiLogo} alt={wikiLogo} />
            </div>
            )
        }
        else
        {
            return(
                <div>
                <img className={this.elem2Left("wiki-image")} src={elem.preview} alt={elem.name}/>
                <img className={this.elem2Left("wiki-logo")} src={wikiLogo} alt={wikiLogo} />
                </div>
            )
        }
    }

    getWikiURL = (element) => {
        // REMOVE AFTER TESTS!
        /*
        console.log("ELEMENTS: ")
        for (let [key, value] of Object.entries(element)) {
            console.log(`${key}: ${value}`);
        }
        console.log("************END************")

        console.log("WIKI URL ELEM: " + element);
        */

        let id = element.external_id;
        if (id.length !== 0) {
            let url = "http://en.wikipedia.org/?curid=" + id;
            return url
        } else {
            return ""
        }
    }


	elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.wikiCount === 1){
			ret = nameClass + "-left";
		}
		if (this.props.wikiCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
	}	
	

    render() {
        var settings = {
            infinite: false,
            slidesToShow: 3,
            arrows: true
        };
     
        if (this.props.wikiCount > 0){
        return (
            <div className="wiki-stops-nearby-desktop">
                <div className="wiki-navigation">
                    <span className="wiki-title">
                    Wikipedia nearby
                    </span>
                    {
                        this.prevnext(this.props.wikiCount > 3)       
                    }
                    </div>
                <Slider ref={c => (this.slider = c)} className="slider" {...settings} >
                        {
                             Object.entries(this.props.wiki).map((elem,idx) =>(
                                <div key={idx}>
                                    <div className="wiki-container">
                                        <a href={this.getWikiURL(elem[1])} target="_blank" rel="noopener noreferrer">
                                            {
                                                this.getWikiImage(elem[1])
                                            }
                                        </a>
                                    </div>
                                    <div className={this.elem2Left("wiki-slide-text")}>
                                        <div className="wiki-slide-title">
                                        {elem[1].name}
                                        </div>
                                    </div>
                                </div>
                             )
                        ) 
                    }
               
                </Slider>
            </div>
        )
        }
        else
        {
            return (<div></div>)
        }
    }
}

export default WikiStopsNearby;