import React from 'react';
import {Helmet} from "react-helmet";


class Meta extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			
			title : "",
			url   : "",
			img   : "",
			mount : false,
			description: ""
			
			
		}
		

	}
	
	
 static getDerivedStateFromProps(props, state) {
    return {
      title: props.og.title,
	  url  : props.og.url,
      img  : props.og.img,
	  description : props.og.description

    }

  }

  componentDidMount() {
    this.setState({ mount: true});
  }	
	
	
	
	render(){

		let addMyMeta = false;
		
		//console.log('navigator :', navigator);
		
		if(this.state.mount && this.state.title !== undefined){
			
			
			
			const metas = document.getElementsByTagName('meta');
			
			let metaOgFound = false;
			
			for (let i = 0; i < metas.length; i++) {
			if (metas[i].outerHTML.includes('property="og:'))  {
				metaOgFound = true;
				
			   }
			}

			if (!metaOgFound){
				
				//console.log('Meta not mount!');
				//console.log('Meta state:',this.state);
				
				document.title = "Stop! "+this.state.title;
			
				let metaType = document.createElement('meta');
				metaType.setAttribute('property', 'og:type');
				
				metaType.content = "website";
				if(addMyMeta){
					document.getElementsByTagName('head')[0].appendChild(metaType);
				}
				
				
				let metaTitle = document.createElement('meta');
				metaTitle.setAttribute('property', 'og:title');
				
				metaTitle.content = "Stop! "+this.state.title;
				if(addMyMeta){
				 	document.getElementsByTagName('head')[0].appendChild(metaTitle);
				}
				
				
				let metaUrl = document.createElement('meta');
				metaUrl.setAttribute('property', 'og:url');
				
				metaUrl.content = this.state.url;
				if(addMyMeta){
					document.getElementsByTagName('head')[0].appendChild(metaUrl);
				}
				
				
				
				let metaImg = document.createElement('meta');
				metaImg.setAttribute('property', 'og:image');
				
				metaImg.content = this.state.img;
				if(addMyMeta){
					document.getElementsByTagName('head')[0].appendChild(metaImg);
				}	
				
				
				let metaDescr = document.createElement('meta');
				metaDescr.setAttribute('property', 'og:description');
				
				metaDescr.content = this.state.description;
				if(addMyMeta){
					document.getElementsByTagName('head')[0].appendChild(metaDescr);
				}
				
				
				
				
			}
		
		}
		
				
  return (
    <div>
	
    <Helmet
	
    meta={[
        
        
       
		{"property": "og:url",    "content": this.props.og.url},
		{"property": "og:description",  "content": "Stop! "+this.props.og.title},
		{"property": "og:image",  "content": this.props.og.img},
	
    
    ]}
    
    
	><title> {"Stop! "+this.state.title}</title>
		</Helmet>
	
	</div>
	)}
}

export default Meta;
