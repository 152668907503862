import React, { Component } from 'react';
import './StopsWatermarkBubble.css';
import watermark from '../../../img/stops_icon_watermark.png';
import placeholderImage from '../../../img/stops_placeholder.png';

class StopsWatermarkBubble extends Component {

    render() {
        var isSmallView = this.props.isSmallView; // Eg. in collection list is small circle
        var isMobile = this.props.isMobile;

        var borderColor = this.props.borderColor;
        if (borderColor === '' || borderColor === undefined) { borderColor = "#0b7ffd"; }
        var borderSize = isMobile ? 3 : 5;
        let borderStyle = borderSize + 'px solid' + borderColor;

        var overlayedIconURL = this.props.bubbleLogo; // Company Logo icon or Stops icon
        if (overlayedIconURL === '' || overlayedIconURL === undefined) { overlayedIconURL = watermark; }

        var stopImageURL = this.props.imageURL;
        if (stopImageURL === '' || stopImageURL === undefined) { 
            overlayedIconURL= '';
            stopImageURL = placeholderImage; 
        }

        if (stopImageURL === placeholderImage) { overlayedIconURL = ''; }

        return (
            <div>
                <div className={"circle-view" + (isSmallView ? "-small" : "") + (isMobile ? "-mobile" : "")} style={{border: borderStyle}}>
                    <img className={"preview-image" + (isSmallView ? "-small" : "") + (isMobile ? "-mobile" : "")} src={stopImageURL} alt="" />

                    {(() => {
                        if(overlayedIconURL) {
                            return (
                                <div className={"stops-nearby-image-container" + (isSmallView ? "-small" : "") + (isMobile ? "-mobile" : "")}>
                                    <img src={overlayedIconURL} className={"stops-watermark" + (isSmallView ? "-small" : "") + (isMobile ? "-mobile" : "")} alt="" />
                                    <div className={"dim-overlay" + (isSmallView ? "-small" : "") + (isMobile ? "-mobile" : "")} />
                                </div>
                            )
                        }
                    }
                    )()}
                </div>
            </div>
        );
    }
}

export default StopsWatermarkBubble;