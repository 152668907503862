import {getStopsHeader,getStopIdString, getCubeIdString,getHereMapsURI,getHereMaps,getWeateherURI,
            getJsonHeader,numProps,getWithinURL,getWikiURI,getHereMapsRoute,
           constructSlider,getCountry, getYelpsStopsURL, getGoogleStopsURL, getWikiStopsURL, fullAddress} from './Storage';

export function getStopsType(callbackF1){
    let header = getStopsHeader();
   
    let id = getStopIdString();
    let url = "https://api.stops.com/stops/" + id;
    let isDebug = false;

   
    let retValue = "";

    fetch(url, header).then(data => {
        return (data.json());
    }).then(data => {        

    if (data.success){  
        retValue = data.data.type;
        if (isDebug){
            console.log('retValue',retValue);
            console.log(data.data);
        }
        callbackF1(retValue);
    }
    else
    {
        window.location.replace("https://stops.com");

        if (isDebug){
            console.log('getStops bad data:');
        }
    }
    })
}

export function getStops(whenDone,stage1,stage2,stage3,stage4,stage5,stage6){

   
    //let isLoad = false;   

    let user =  {
        firstName : "",
        lastName: "",
        img: "",
        unreadMsgCount : 0,
        fullName: ""};
  
  let photoinfo= {
        id : 0,
        slider: {},
        title: "",
        description: "",
        month: "",
        day : 0,
        year: 0,
        hashtags: {},
        fullAddress : ""

                     } 

  
  let locationinfo = {

                altitude: 0,
                latitude: 0,
                longitude: 0,
    
                hereMapsURI : "",
                imageMapURI : ""
  
                };

  let attachements = {
                attachements    : {},
                attsCount:0
                                  };

  let weather ={
                current_temp_C  : 0,
                current_temp_F  :0,
                condition : "",
                weatherIconUrl : ""
                };

  let within = {
                within: {},
                withinCount  : 0
                };

  let yelps = {
                yelps : {},
                yelpsCount :0
                };
                     
let wiki = {
            wiki:{},
            wikiCount : 0
        };    
        
let google = {
    google: {},
    googleCount : 0
}


let wikiStop = {
    wiki:{},
    wikiCount : 0
}
        
// facebook open graph            
let og = {
    title : "",
    type: "website",
    url: "",
    img : "",
   description : ""
}   

/*
let business = {
        email: "",
        phone: ",",
        last_name: "",
        first_name: "",
        website: "",
        reference: "",
        hours: {}
}
*/  
  
  

       let isMobile = window.innerWidth <= 700;
       let header = getStopsHeader();
       let jsonHeader = getJsonHeader();     
       let id = getStopIdString();
       let url = "https://api.stops.com/stops/" + id;
       let isDebug = false;
       let userID = 0;
       let monthNames = ["January", "February", "March", "April", "May", "June",
       "July", "August", "September", "October", "November", "December"
             ];
      let stops = {};
    


       fetch(url, header).then(data => {
           return (data.json());
       }).then(data => {        

       if (data.success){
 
         
           if (isDebug){
               console.log('getStops :',data.data);
           }
           userID = data.data.user_id;
           if (userID !==0){
               //console.log('41: ',userID);

               let stopsType = data.data.type; 
               if (isDebug){
                    console.log('type: ', stopsType);
               }
               stops = data.data;
               //console.log(stops);
               let dateObj  = new  Date(stops.updated_at);
               let slider = {};
               let pixelBubble = {defined: false,
                                                bubbleTop: 0,
                                                bubbleLeft : 0, 
                                                bubbleRight: 0,
                                                bubbleBottom: 0,
                                                photoX: 0,
                                                photoY: 0
                                               
                                            };

                let business = {
                                            email: "",
                                            phone: ",",
                                            last_name: "",
                                            first_name: "",
                                            website: "",
                                            reference: "",
                                            hours: {}
                                       };   

                if (stopsType === 'business'){
                    business.email = stops.contact.email;
                    business.phone = stops.contact.phone;
                    business.last_name = stops.contact.last_name;
                    business.first_name = stops.contact.first_name;
                    business.hours = stops.hours;
                    business.website= stops.website;
                    business.reference= stops.reference;

                }
               if (stopsType !== 'pixel'){
                   let  s = constructSlider(stops.items);
                   //console.log(s);
                    slider = s; // stops.items.filter(elem => (isMusicElem(elem)  || isPhotoElem(elem)))
                    //console.log(slider);
               }
               else
               {
                  
                   if ((stops.photo !== undefined) && ((stops.photo.x > 0) && ((stops.photo.y > 0))) ){

                    pixelBubble = {
                        defined: true,
                        bubbleTop : stops.top,
                        bubbleLeft: stops.left,
                        bubbleRight: stops.right,
                        bubbleBottom: stops.bottom,
                        photoX: stops.photo.x,
                        photoY: stops.photo.y
 
                    }
                          
                   }
               }    
                    photoinfo= {
                        id: id,
                        type: stops.type,

                            slider: slider,
                            title:  stops.name,
                            description: stops.description,

                            month: monthNames[dateObj.getMonth()],
                            day : dateObj.getDate(),
                            year: dateObj.getFullYear(),

                            hashtags: stops.hashtags,
                            pixelBubble: pixelBubble,
                            business : business

                        }  
                        //console.log('photoinfo',photoinfo);
                        let ogImg = "https://content.readymag.com/5993b1a9b61de313c73f1b2f/842556/screenshot-ee2d27df-bbaf-412a-8685-08624d9985c3_readyscr_1024.jpg";
                        if (stopsType !== 'pixel') {
                            if (numProps(photoinfo.slider) > 0 )
                            {
                                ogImg = photoinfo.slider[0].preview;
                            }
                        }
                        else
                        {
                            photoinfo.slider[0]=    {type:"photo", 
                                                                id:stops.photo.id, 
                                                                preview:stops.photo.url,
                                                                file_name:stops.name, 
                                                                href: ""
                                                                };

                            ogImg = photoinfo.slider[0].preview;  
                        }

                        og = {
                            title: stops.name,
                            img: ogImg,
                            url : window.location.href,
                            description : stops.description
                        }
                        
                        if (isDebug){
                            console.log('og :',og);
                        }
            

				/*
                let street = (stops.address.street===null ? "": stops.address.street);
                let cntry = (stops.address.country===null ? "": stops.address.country);
                cntry =  getCountry(cntry);
				let city   = (stops.address.city   === null ? "":stops.address.city);
                let cnty   = (stops.address.county === null ? "":stops.address.county);
                let dstrct = (stops.address.district === null ? "": stops.address.district);
                let nmbr = (stops.address.number === null ? "" : stops.address.number);
                let postcd = (stops.address.postcode === null ?  "": stops.address.postcode);
                let stat      = (stops.address.state === null ?  "" : stops.address.state);
				*/
				
				let fAddr =  new fullAddress({
									street: stops.address.street,
									numb  : stops.address.number,
									city  : stops.address.city,
									postcode : stops.address.postcode,
									county   : stops.address.county,
									district : stops.address.district,
									state    : stops.address.state,
									country  : stops.address.country
									
									}
								);
								
				let fulladdress=fAddr.fullAddress;
				/*
				let fulladdress = (street   + " "+ nmbr  + ", "+
                                            city       +" " + postcd+" "+
                                            cnty +" "+ dstrct     +" "+
                                            stat    +" "+ cntry);
											
				if (fulladdress.trim() === ","){
						fulladdress = "";
					}
				*/	
                locationinfo = {

                    altitude: parseFloat(stops.location.altitude).toFixed(0),
                    latitude: stops.location.latitude,
                    longitude: stops.location.longitude,
        
                    hereMapsURI :  getHereMapsURI(isMobile,stops.location.latitude,stops.location.longitude),
                    imageMapURI : getHereMaps(isMobile,stops.location.latitude,stops.location.longitude),
                    fullAddress : fulladdress
					
                };         
                
                if (isDebug){
                    console.log('locarionInfi :',locationinfo);
                }

                

                if (stopsType !== 'pixel'){
                    attachements = {

                            attachements    : stops.items.filter(
                                                                        elem =>  (
                                                                                        (elem.type === 'file' ) && 
                                                                                        (elem.file.url.trim().length !== 0)
                                                                                        )
                                                                                ),

                            attsCount : numProps(
                                stops.items.filter(
                                                    elem =>  (
                                                                    (elem.type === 'file' ) && 
                                                                    (elem.file.url.trim().length !== 0)
                                                                    )
                                                        )
                                                    )
											
                                                }
                    }
                    else
                    {
                        attachements = {
                            attachements    : {},
                            attsCount : 0
                        }
                    }
                                        
                   stage1(photoinfo,locationinfo,attachements,og);                     
 
             url = "https://api.stops.com/users/" + userID;
               fetch(url, header).then(data => {
                   return (data.json());
               }).then(data => {   
                   
                   if (isDebug){
                       console.log('getUser data.data',data.data);
                   }

                   // User Object
  
                        user =  {

                                    firstName : data.data.first_name,
                                    lastName:data.data.last_name,
                                    img:  data.data.img,
                                    unreadMsgCount : data.data.unread_messages_count,
                                    fullName:  (data.data.first_name + " "+ data.data.last_name)

                                };

                                stage2(user);
                    


                   //});

               }).then(data => {
                 

                 url = getWeateherURI(stops.location.latitude,stops.location.longitude);

 
            
                 fetch(url,  jsonHeader).then(data => {
                     return (data.json());
                 }).then(weatherdata => {              
                        if (isDebug){
                            console.log('weatherdata: ',weatherdata);
                        }
                         weather = {
                                        current_temp_C  : weatherdata.data.current_condition[0].temp_C,
                                        current_temp_F  : weatherdata.data.current_condition[0].temp_F,
                                        code                  : process.env.PUBLIC_URL + "/img/weatherIcons/"+weatherdata.data.current_condition[0].weatherCode+".png",
                            
                    
                                        condition : weatherdata.data.current_condition[0].weatherDesc[0].value,
                    
                                        weatherIconUrl : weatherdata.data.current_condition[0].weatherIconUrl[0].value
                         }

                         stage3(weather);
     

                     
                 })
         
               }

               ).then(withindata =>{
                    url = getWithinURL(stops.location.latitude,stops.location.longitude);
                    if (isDebug){
                        console.log('362:',url);
                    }
                    fetch(url, header).then(data => {
                      return (data.json());
                  }).then(data => {   
                      
                      if (isDebug){
                          console.log('StopsNearby data.data: ',data.data);
                      }

                      // filter self element
                      let arr  = data.data.filter(elem => (elem.location.latitude    !== stops.location.latitude) && 
                                                                            (elem.location.longitude !== stops.location.longitude)
                                                            );
                      // странички делаем для pixel tags, cubes, collections, stops: basic, memory, business, youtube, sale, fact, timecapsule
                      if (isDebug){
                        console.log('data.data, id, arr: ',data.data,id,arr);
                        console.log('stop location',stops.location.latitude,stops.location.longitude);
                    }
                      // Within
           
                       let arrWithin = arr.filter(elem =>         (elem.type === 'pixel' ) ||
                                                                    (elem.type === 'drop' ) ||
                                                                    (elem.type === 'business' ) ||
                                                                    
                                                                    (elem.type === 'basic' ) ||
                                                                    (elem.type === 'memory' ) ||
                                                                   
                                                                    (elem.type === 'youtube' ) ||
                                                                    (elem.type === 'sale' ) ||
                                                                    (elem.type === 'fact' ) ||
                                                                    (elem.type === 'timecapsule' ) 
                       );
                        // Yelps

        
                       let arrYelps     = arr.filter(elem =>         (elem.type === 'yelp' ) );
                       let arrGoogle = arr.filter(elem =>         (elem.type === 'google' ) );
                       let arrWiki      = arr.filter(elem =>         (elem.type === 'wiki' ) );

                       //this.setState({  
                         within       = { 
                                                within: arrWithin,
                                                withinCount  : numProps(arrWithin)
                                            };

                        yelps =   { 
                                        yelps : arrYelps,
                                        yelpsCount : numProps(arrYelps)
                                    };

                        google = {
                                        google: arrGoogle,
                                        googleCount :numProps(arrGoogle)
									};
								
						wikiStop = {
										wiki:arrWiki, 
										wikiCount:numProps(arrWiki)
									}; 
									
                        // ----------------------------------------                
						// !Delete this 
						// this Top 
						/*
						if (wikiStop.wikiCount > 0){
							wikiStop = {
										wiki:[arrWiki[0]], 
										wikiCount:1
									}; 
						}
						
						if (google.googleCount > 0){
							console.log('googleCount',google);
							google = {
                                        google: [arrGoogle[0]],
                                        googleCount :1
                                    } 
							console.log('googleCount',google);		
						}

						if (yelps.yelpsCount > 0){
							yelps =   { 
                                        yelps : [arrYelps[0]],
                                        yelpsCount : 1
                                    };
						}	

						if (within.withinCount > 0){
							within       = { 
                                                within: [arrWithin[0]],
                                                withinCount  : 1
                                            };
						}
						*/
						// this Bottom
						// !Delete this 
                        // ----------------------------------------            
                                    
                         if (isDebug){
							 console.log('wikiStops Base',wikiStop);
						 }		



                        stage4(within, yelps, google,wikiStop);            

                         //  });
            }).then(wikidata =>{
              // Wiki

              url = getWikiStopsURL(stops.location.latitude,stops.location.longitude);

              fetch(url,  header).then(data => {
                return (data.json());
            }).then(data => {        
                
               if (isDebug){
                   console.log('getWIKI data.query.pages:',data.query);
               }
               /*
                 wiki         = {wiki: data.query.pages,
                                     wikiCount : numProps(data.query.pages)}
                */
				let arrWiki = data.data;
				wikiStop = {
										wiki:arrWiki, 
										wikiCount:numProps(arrWiki)
							}; 	

                // ----------------------------------------                
				// !Delete this 
				// this Top
				/*
				if (wiki.wikiCount > 0){
					console.log('googleCount',wiki);
					let first_el;
					let key;
					for (var k in data.query.pages){
						console.log('googleCount',k[1])
						first_el = data.query.pages[k];
						key = k;
						break;
					}		
					wiki = {
								wiki: {first_el}, 
								wikiCount:1
							};
					console.log('googleCount',wiki);		
				}
				*/
				// this Bottom
				// !Delete this 
				// ----------------------------------------            
						
						
                    //isLoad = true;
                    // stage5(wiki);
					stage4(within, yelps, google,wikiStop);   
                    //whenDone(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);                   
            
            }).then(dcomments => {


                url = "https://api.stops.com/stops/" + id+"/comments";
                fetch(url, header).then(data => {
                    return (data.json());
                }).then(data => {  

                    
                    
                   
                    let comments = {
                        comments : data.data,
                        comCount : numProps(data.data)};


                    if (isDebug){
                        console.log('Comments : ', comments);
                    }

                    stage6(comments);

                    
                 }
                ).catch(function(err)
                {
				if (isDebug){	
					
                  console.log(err);
                  alert('Commentsi Fetch Error : ', err); //! delete  ? what to do
				}
				else
				{
					window.location = "https://stops.com/";
				}
                });
                


            }).catch(function(err)
        {
			if (isDebug){		
				console.log(err);
				alert('Wiki Fetch Error : ', err); //! delete  ? what to do
			}
			else
			{
				window.location = "https://stops.com/";
			}
        });
           
    
            });
          

               });
           }
           
       }
       else
       {
           if (isDebug){
               console.log('getStops bad data:');
           }
		   else
		  {
			    window.location = "https://stops.com/";
		  }
           //window.location.replace("https://stops.com");
       }
      }).catch(function(err)
     {
		if (isDebug){	 
			console.log("Stops Fetch Error : " + err);
            //alert('Stops Fetch Error : ', err); //! delete  ? what to do
		}
		else
		{
            // Uncomment!
			window.location = "https://stops.com/";
		}
     })      

    }
//============================================

export function getCollection(whenDone){

   
    let isLoad = false; 
    let isMobile = window.innerWidth <= 700;  

    let user =  {
        firstName : "",
        lastName: "",
        img: "",
        unreadMsgCount : 0,
        fullName: ""};
  
  let collection= {
        //slider: {},
        id : 0,
        title: "",
        count: 0,
        
        month: "",
        day : "",
        year: "",
        url : "",
        thumb : "",
        thumbText : ""

  }  

   let route = {   route : [],
      routeString: "&r0=",
      pinString: "",
      routeURL: ""

                     }; 

    // facebook open graph       
    let og = {
        title : "",
        type: "website",
        url: "",
        img : "",
        description : ""
}        
                        

         

    let items = [];

  
       
       let header = getStopsHeader();
       //let jsonHeader = getJsonHeader();     
       let id = getStopIdString();
      
       let url = "https://api.stops.com/collections/" + id;
       let isDebug = false;
       let userID = 0;
       
       let monthNames = ["January", "February", "March", "April", "May", "June",
       "July", "August", "September", "October", "November", "December"
             ];
            
      let dbcollection = {};


      fetch(url, header).then(data => {
        return (data.json());
        }).then(data => { 
         if (data.success){
                   
                             
             dbcollection = data.data;
             if (isDebug){
                 console.log('Collection: ',data);
             }
             let dateObj  = new  Date(dbcollection.updated_at);
             
                           
            collection= {
                id: id,

                 title: dbcollection.name,
                 count: dbcollection.count,
                 
                 month: monthNames[dateObj.getMonth()],
                 day : dateObj.getDate(),
                 year: dateObj.getFullYear(),
                 url : dbcollection.url,
                 thumb: dbcollection.thumb
                 
             }  



             userID = data.data.user_id;
             if (userID !==0){
                 if (isDebug){
                     console.log('userID: ',userID);
                 }
                 
                 url = "https://api.stops.com/users/" +userID ;		

                 fetch(url, header).then(data => {
                     return (data.json());
                 }).then(data => {   
                        
                     if (isDebug){
                         console.log('getUser data.data',data.data);
                     }

                     // User Object
       
                     user={

                         firstName : data.data.first_name,
                         lastName:data.data.last_name,
                         img:  data.data.img,
                         unreadMsgCount : data.data.unread_messages_count,
                         fullName:  (data.data.first_name + " "+ data.data.last_name)

                         };
                         

                    }).then(itemsdata => {


                        url = "https://api.stops.com/collections/" + id+'/items';


                        fetch(url, header).then(data => {
                            return (data.json());
                        }).then(data => {  

                            if (isDebug){
                                console.log('getItems data.data',data.data);
                            }


                            let pinsColor =  ["black","blue", "red","ff6781"];
                           
                           
                            let dataLength = (numProps(data.data)-1);

                            //let elemSelf = data.data.filter()
                           
                            for (var i = 0;  i <= dataLength; i++){

                                let stops = data.data[i];

                               

                                let item = {

                                    id : 0,
                                    position : 0,
                            
                                    created_at_day: "",
                                    created_at_month: "",
                                    created_at_year : "",
                            
                                    updated_at_day: "",
                                    updated_at_month : "",
                                    updated_at_year : "",
                            
                                    visit_at_day: "",
                                    visit_at_month : "",
                                    visit_at_year : "",
                                    createdBy        : "",
                            
                                 
                                    slider : {},
                                    title: "",
                                    description: "",
                                    month : "",
                                    day: "",
                                    year : "",
                                    hashtags : "",
                                    fullAddress : "",
                                    hereMapsURI : "",
                                    stopURL : ""

                                    
                                };      

                                let dateObjU  = new  Date(stops.updated_at);
                                let dateObjC  = new  Date(stops.created_at);
                                let dateObjV  = new  Date(stops.visit_at);
                                let dateObj  = new  Date(stops.stop.updated_at);

               
                                item.id = stops.id;

                                item.created_at_day      = dateObjC.getDate();
                                item.created_at_month = monthNames[dateObjC.getMonth()];
                                item.created_at_year     = dateObjC.getFullYear();
                        
                                item.updated_at_day      = dateObjU.getDate();
                                item.updated_at_month = monthNames[dateObjU.getMonth()];
                                item.updated_at_year     = dateObjU.getFullYear();
                        
                                item.visit_at_day            = dateObjV.getDate();
                                item.visit_at_month       = monthNames[dateObjV.getMonth()];
                                item.visit_at_year           = dateObjV.getFullYear();

                                item.position = stops.position;
                                route.route.push(stops.stop.location);
                                route.routeString += stops.stop.location.latitude+","+stops.stop.location.longitude;
                                route.pinString += "&poix" + (i) + "="+stops.stop.location.latitude+","+stops.stop.location.longitude+
                                ";"+pinsColor[(i+1) %4]+";white;18;"+(i+1);
                                if (i !== dataLength){
                                    //console.log('dataLength:',dataLength);
                                    //console.log('i: ',i);
                                    route.routeString += ",";
                                    //route.pinString += ",";
                                }

                                /*
                                longitude: "34.7641977954163792"
                                latitude: "32.0883922084498039"
                                altitude: "0.0000000000000000"
                                */


                                item.createdBy  = user.fullName;
                                //console.log(stops.stop.type);
                        
                                if ((stops.stop.type === "drop") || (stops.stop.type === "business")){
                                            item.slider =  stops.stop.items.filter(elem => ((elem.photo !== undefined ) && (elem.photo.id !== 0)));
                                            if (numProps(item.slider) === 0){
                                                // no photo 
                                                // search link
                                               
                                                if ((stops.stop.preview !== undefined) && ((stops.stop.preview !== null)) && (stops.stop.preview.trim() !== ""))
                                                {
                                                    item.slider[0] =   {type:"photo",photo:{ 
                                                                id:stops.stop.id, 
                                                                url:stops.stop.preview,
                                                                file_name:stops.stop.name, 
                                                                href: ""
                                                                }};
                                                }
                                                else
                                                {
                                                    
                                                    if ((stops.stop.items !== undefined)  && (numProps(stops.stop.items) > 0)){
                                                                if ((stops.stop.items[0].type === "link") && (stops.stop.items[0].link.preview !== "")){

                                                                item.slider[0] =   {type:"photo",photo:{ 
                                                                id:stops.stop.id, 
                                                                url:stops.stop.items[0].link.preview,
                                                                file_name:stops.stop.name, 
                                                                href: ""
                                                                        }
                                                                };   

                                                            }
                                                            else if ((stops.stop.items[0].type === "photo") && (stops.stop.items[0].photo.url !== ""))
                                                            {
                                                                item.slider[0] =   {type:"photo",photo:{ 
                                                                    id:stops.stop.id, 
                                                                    url:stops.stop.items[0].photo.url,
                                                                    file_name:stops.stop.name, 
                                                                    href: ""
                                                                            }
                                                                    };   
                                                                   
                                                            }
                                                        }            
                                                    
                                                }
                                                
                                                /*
                                                let link = stops.stop.items.filter(elem => ((elem.link !== undefined ) && (elem.link.preview !== "")));
                                                 item.slider[0] =   {type:"photo",photo:{ 
                                                                id:stops.stop.photo.id, 
                                                                url:stops.stop.photo.url,
                                                                file_name:stops.stop.name, 
                                                                href: ""
                                                                }};
                                                */

                                            }
                                }
                                 else if (stops.stop.type === "pixel") {
                                      item.slider[0] = {type: "photo",photo: { 
                                                                id:stops.stop.photo.id, 
                                                                url:stops.stop.photo.url,
                                                                file_name:stops.stop.name, 
                                                                href: ""
                                                        }};
                                //   item.slider[0].stops.stop.photo;
                                } else if (stops.stop.type === "wiki") {
                                    item.slider[0] = {type: "photo",photo: { 
                                        id: stops.stop.id, 
                                        url: stops.stop.photo, // maybe 'preview' better idea but it's does't loading for me (ex. https://prod-spaces.ams3.digitaloceanspaces.com/224518006108062904)
                                        file_name: stops.stop.name, 
                                        href: ""
                                    }};
                                } else if (stops.stop.type === "yelp") {
                                    item.slider[0] = {type: "photo",photo: { 
                                        id: stops.stop.id, 
                                        url: stops.stop.photo, // maybe 'preview' better idea but it's does't loading for me (ex. https://prod-spaces.ams3.digitaloceanspaces.com/224518006108062904)
                                        file_name: stops.stop.name, 
                                        href: ""
                                    }};
                                } else if (stops.stop.type === "youtube") {
                                    const photoURL = stops.stop.content.youtube.items[0].link.preview;
                                    if (photoURL !== undefined && photoURL !== '') {
                                        item.slider[0] = {type: "photo",photo: { 
                                            id: stops.stop.id, 
                                            url: photoURL, // maybe 'preview' better idea but it's does't loading for me (ex. https://prod-spaces.ams3.digitaloceanspaces.com/224518006108062904)
                                            file_name: stops.stop.name, 
                                            href: ""
                                        }};
                                    }
                                }


                                if (item.slider === undefined) {
                                    item.slider[0] =   {type:"photo",photo:{ 
                                        id:stops.stop.id, 
                                        // url:"/img/bubble.png",
                                        file_name:stops.stop.name, 
                                        href: ""
                                                }
                                        };   
                                }
								
								//console.log(item.slider[0] );
								
								if (item.slider[0] === undefined)
								{
									
									item.slider[0] =   {type:"photo",photo:{ 
                                        id:stops.stop.id, 
                                        // url:"/img/bubble.png",
                                        file_name:stops.stop.name, 
                                        href: ""
                                                }
                                        };   	
								}		
                                

                                item.title =  stops.stop.name;
                                item.description= stops.stop.description;

                                item.month= monthNames[dateObj.getMonth()];
                                item.day = dateObj.getDate();
                                item.year= dateObj.getFullYear();

                                item.hashtags= stops.stop.hashtags;
                                item.hereMapsURI =  getHereMapsURI(isMobile,stops.stop.location.latitude,stops.stop.location.longitude);
                                item.stopURL = stops.stop.url;
								
								let fAddr =  new fullAddress({
									street: stops.stop.address.street,
									numb  : stops.stop.address.number,
									city  : stops.stop.address.city,
									postcode : stops.stop.address.postcode,
									county   : stops.stop.address.county,
									district : stops.stop.address.district,
									state    : stops.stop.address.state,
									country  : stops.stop.address.country
									
									}
								);
								
								item.fullAddress = fAddr.fullAddress
                            
                                items.push(item);                                
                                
                            };

                            route.routeURL = getHereMapsRoute(isMobile,route.routeString,route.pinString);

                            // replace it if you want another picture

                            let ogImg = "https://content.readymag.com/5993b1a9b61de313c73f1b2f/842556/screenshot-ee2d27df-bbaf-412a-8685-08624d9985c3_readyscr_1024.jpg";
                           
                            if (dataLength > 0){ 
                                 let nexthumb = Math.floor(Math.random() * (dataLength-1));
                             
                                collection.thumb = items[nexthumb].slider[0].photo.url;
                                ogImg                 = items[nexthumb].slider[0].photo.url;
                                collection.thumbText = {number:(nexthumb+1).toString(), title: items[nexthumb].title};

                            }

                                            
                           
                            og = {
                                title: dbcollection.name,
                                img: ogImg,
                                url : window.location.href,
                                description: dbcollection.name
                            }
                            if (isDebug){
                                console.log('og :',og);
                                console.log('route: ',route);
                            }
                            

                            isLoad = true;
                            whenDone(isLoad, user,collection,items,og,route);      

                            

                        })
                        
                    })
                    
                    
                    .catch(function(err)
                     {
						 if (isDebug){	
							console.log(err);
							alert('User Fetch Error : ', err); //! delete  ? what to do
						 }
						 else
						{
							window.location = "https://stops.com/";
						}
                     });
                                
             }
         }
         else
         {
			 if (isDebug){	
				console.log('Collection Data Error', data );
				alert('Collection Data Error : '); //! delete  ? what to do
			 }
			 else
			{
				window.location = "https://stops.com/";
			}
         }
        
    }       

    
    ).catch(function(err)
         {
			 if (isDebug){	
				console.log(err);
				alert('Collection Fetch Error : ', err); //! delete  ? what to do
			 }
			else
			{
				window.location = "https://stops.com/";
			}			 
         });
    }
           
       
    
    
    