import React, { Component } from 'react';
import CubeDesktop from './CubeDesktop/AppCubeDesktop/AppCubeDesktop.js';
import CubeMobile from './CubeMobile/AppCubeMobile/AppCubeMobile.js';

class Cube extends Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    };

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        document.title = 'Cube'
    }

    render() {

        const {width} = this.state;
        const isMobile = width <= 700;

        if (isMobile) {
            return (
                <CubeMobile />
            );
        } else {
            return (
                <CubeDesktop />
            );
        }
    }
}

export default Cube;