class CategoryKeeper {

    CategoryTypes = {
        food: 'food',
        sports: 'sports',
        entertainment: 'entertainment',
        landmarks: 'landmarks',
        nature: 'nature',
        services: 'services',
        health: 'health',
        shopping: 'shopping',
        transport: 'transport',
        real_estate: 'real_estate',
        education: 'education',
        art: 'art',
        religious: 'religious',
        adult: 'adult',
        other: 'other'
    };

    colorOfCategory(category) {
        switch(category) {
            case this.CategoryTypes.food:
                return "#35BEFF";
            case this.CategoryTypes.sports:
                return "#F6412E";
            case this.CategoryTypes.entertainment:
                return "#0B93FB";
            case this.CategoryTypes.landmarks:
                return "#C53F00";
            case this.CategoryTypes.nature:
                return "#FE9A00";
            case this.CategoryTypes.services:
                return "#6ECA00";
            case this.CategoryTypes.health:
                return "#00DB82";
            case this.CategoryTypes.shopping:
                return "#3F51B5";
            case this.CategoryTypes.transport:
                return "#7CB1C9";
            case this.CategoryTypes.real_estate:
                return "#EB1662";
            case this.CategoryTypes.education:
                return "#875A4B";
            case this.CategoryTypes.art:
                return "#9D1DB2";
            case this.CategoryTypes.religious:
                return "#DAC175";
            case this.CategoryTypes.other:
                return "#45697b";
            /* Will be back after will be added on admin panel
            case this.CategoryTypes.adult:
                return ""; //-- Need to ask Natha about color! [MARK]
            */
            default:
                return "#45697b";
        }
    }

    nameOfCategory(category) {
        switch(category) {
            case this.CategoryTypes.food:
                return "Food";
            case this.CategoryTypes.sports:
                return "Sports";
            case this.CategoryTypes.entertainment:
                return "Entertainment";
            case this.CategoryTypes.landmarks:
                return "Landmarks";
            case this.CategoryTypes.nature:
                return "Nature";
            case this.CategoryTypes.services:
                return "Services";
            case this.CategoryTypes.health:
                return "Health";
            case this.CategoryTypes.shopping:
                return "Shopping";
            case this.CategoryTypes.transport:
                return "Transport";
            case this.CategoryTypes.real_estate:
                return "Real Estate";
            case this.CategoryTypes.education:
                return "Education";
            case this.CategoryTypes.art:
                return "Art";
            case this.CategoryTypes.religious:
                return "Religious";
            case this.CategoryTypes.other:
                return "Other";
            /* Will be back after will be added on admin panel
            case this.CategoryTypes.adult:
                return "Adult";
            */
            default:
                return ""
        }
    }
}

export default CategoryKeeper;