import React, { Component } from 'react';
import './GoogleArticlesNearby.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import next from '../next-arrow.png';
import prev from '../prev-arrow.png';

//import googleLogo from './512px-Google_Logo.svg';
import googleLogo from './googlePlus1600.png';
import {getDescription} from '../../../Storage/Storage.js';

class GoogleArticlesNearby extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
	
    elementToLeft = (numItems) => {
        let ret = "attachment-item";
        if (numItems === 1) {
            ret = "attachment-item-1"
        }
        return ret;
    }
	
	elem2Left = (nameClass) => {
		let ret = nameClass;
		if (this.props.googleCount === 1){
			ret = nameClass + "-left";
		}
		if (this.props.googleCount === 2){
			ret = nameClass + " " + nameClass+"-two";
		}
		return ret;
	}
	
	
		
	
    getGoogleURL = (elem) => {
        let baseURL = "https://www.google.com/maps/search/?api=1"
        let id = elem.external_id;
        let location = [elem.location.longitude, elem.location.latitude];
        baseURL += "&query=" + location[0] + "," + location[1]; // 47.5951518,-122.3316393; 
        baseURL += "&query_place_id=" + id;

        if (id.length !== 0) {
            return baseURL;
        } else {
            return ""
        }
    }

    getGoogleImage = (elem) =>{
		    //let googleLogo =  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png";
            
				let img = elem.preview;
				//console.log('googlePhoto',img);
				if (!img ){
					img = elem.photo;
				}
				if (!img ){
					img = elem.icon;
				}
				if (!img ){
					img = ""
				}
						
                return(
                    <div className='content'>
					
                    <img className={this.elem2Left("google-image")} src={img}        alt={elem.title}/>
					<img className={this.elem2Left("google-logo" )} src={googleLogo} alt="Google" />
                    
                    </div>
                )


            



    }

    prevnext = (count) =>{
        if (this.isDebug){
            console.log('count',count);
        }
        count = 0; // Natan ask so. No Need arrows in mobile
        if (count > 2){
        return (
            <div>
            <span><img className="prev arrow" src={prev} onClick={this.previous}  alt="prev"/></span>
            <span><img className="next arrow" src={next} onClick={this.next}  alt="next"/></span>
            </div>
        )
        }
        else
        {
            return(
            ""
            )
        }
    }



    render() {
		
        var settings = {
            infinite: false,
            slidesToShow: 3,
            swipeToSlide: true
            // centerPadding: '0px'
        };
        if (this.props.googleCount > 0){
        return (
            <div className="google-articles-nearby-mobile">
                <div className="google-navigation">
                    <span className="google-title">
                    Google nearby
                    </span>
                     {
                        
                        this.prevnext(this.props.googleCount )
                      
                        
                   }
 
                </div>
                <Slider className="slider" ref={c => (this.slider = c)} {...settings} onClick={() => console.log("test")}>
                    {
                             Object.entries(this.props.google).map((elem,idx) =>(
                                 <div key={idx} className="box">
                                    <a href={this.getGoogleURL(this.props.google[idx])} target="_blank"  rel="noopener noreferrer">
                                        {
                                                this.getGoogleImage(this.props.google[idx])
                                                
                                        }
                                    </a>
										<div className={this.elem2Left("google-slide-text")}>
                                        {/*
										<a href={this.getGoogleURL(this.props.google[idx])} target="_blank" rel="noopener noreferrer">
										*/}
											<div className="google-slide-title">
                                            {getDescription(this.props.google[idx].name, 40)}
                                            </div>
											{/*
										</a>  
											*/}
                                        
                                    </div>
                                </div>

                                
                             )
                        
                        ) 
                    }
                </Slider>
            </div>
        )
    }
                else
    {return(<div></div>)}
    }
}

export default GoogleArticlesNearby;