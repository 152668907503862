import React, { Component } from 'react';
// import "../../--CubeMobileOld/LargeCarousel/node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
// import againstMe from './against-me.jpeg';
// import chicagoFans from './chicago-fans.jpg';
// import coffeeShop from './coffee-shop.png';
import imagePlaceholder from './cube_image_placeholder.png';
import './LargeCarousel.css';

class LargeCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { image } = this.props;

        let cubeImage = image === "" ? imagePlaceholder : image;

        return (
            <div className="large-carousel-cube-desktop">
                <img className="cube-photo mask-cube-image" src={cubeImage} alt={cubeImage} />
            </div>
        );
    }
}

export default LargeCarousel;