import React, { Component } from 'react';
import StopDesktop from './StopDesktop/AppStopDesktop/AppStopDesktop.js'
import StopMobile from './StopMobile/AppStopMobile/AppStopMobile.js'
import {AppEnv} from '../Storage/Environment.js';

class Stop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            stopsObj : {},
			env:AppEnv()
        };
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.title = 'Stop'
    }

    render() {

        const {width} = this.state;
        const isMobile = width <= 700;

        if (isMobile) {
            return (
                <StopMobile  />
            );
        } else {
            return (
                <StopDesktop  />
            );
        }
    }
}

export default Stop;