import React, { Component } from 'react';
//import { HereMap, Marker } from 'rc-here-maps';
import './Map.css';
//import map from './map.jpg'
import pin from './pin.png'
import StopsBubbleView from './StopsBubbleView/StopsBubbleView.js';
// import {getStopIdString,getHereMapsURI,getHereMapsDeskTop} from '../../../Storage/Storage';

// Dummy -> Will be icon from server
import watermark from '../../../img/stops_icon_watermark.png';

class Map extends Component {
  
    render() {
        return (
            <div className="map-stop-desktop">
                <a href={this.props.hereMapsURI} target="_blank" rel="noopener noreferrer" >
                    <div className="map-container">
                        <img className="map-image" src={this.props.imageMapURI} alt={this.props.title}/>
                        <StopsBubbleView bubbleColor="#5cabff" bubbleLogo={watermark} />
                    </div>
                </a>
                       
           </div>
        );
    }
}

export default Map;