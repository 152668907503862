import React, { Component } from 'react';
import './LocationInfo.css';
import pinIcon from './pin-icon.png'
import altitudeIcon from './altitude-icon.png'



class LocationInfo extends Component {
   
meters2feet = (mtr) => {
	
	return (mtr*3.28084).toFixed(0)
}  
seaLevel = () => {
	let ret="above";
	if (this.props.altitude < 0){
		ret = "below"
	}
	return ret;
} 
  
    render() {
    
        return (
            <div className="location-info-stop-desktop">
                <div className="location">
                    <div className="location-row">
                        <span><img className="pin-icon" src={pinIcon} alt={pinIcon}/></span>
                        <span
                            className="location-text"><strong>Location:</strong>
                            <a href={this.props.hereMapsURI} target="_blank" rel="noopener noreferrer" >
                                <span className="location-link"> {this.props.fullAddress}</span>
                            </a>
                        </span>
                    </div>
                </div>
                <div className="altitude">
                    <div className="altitude-row">
                        <span><img className="altitude-icon" src={altitudeIcon} alt={altitudeIcon}/></span>
                        <span className="altitude-text"><strong>Altitude:</strong> {this.props.altitude} meters / {this.meters2feet(this.props.altitude)} feets<span className="sea-level"> ({this.seaLevel()} sea level)</span></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default LocationInfo;