import DownloadBanner from '../../../SharedComponents/Mobile/DownloadBanner/DownloadBanner.js'
import Navbar from '../../../SharedComponents/Mobile/Navbar/Navbar.js';
import Meta from  '../../../Meta/Meta.js';
import TravelDashboard from '../TravelDashboard/TravelDashboard.js'
import PinInfo from '../PinInfo/PinInfo.js'
import Features from '../../../SharedComponents/Mobile/FeaturesMobile/Features.js'
import Footer from '../../../SharedComponents/Mobile/FooterMobile/Footer.js'
import React from 'react';
import './AppCollectionsMobile.css';

import {getCollection} from '../../../Storage/dataBroker.js';
import {AppEnv} from '../../../Storage/Environment.js';
//import {getStopIdString} from '../../../Storage/Storage.js';


class  AppCollectionsMobile extends React.Component {

	constructor(props){
		super(props);
		
		this.state ={
        isLoad : false,
        

        id : 0,
				// User
				firstName : "",
				lastName: "",
				img: "",
				unreadMsgCount : 0,
				fullName: "",

				// Collection
        title: "",
        count: "",
        
        month: "",
        day : "",
        year: "",
        url : "",
        thumb : "",
        thumbText: "",

        items: [],

        
        // facebook open graph       
      og : {},

      env: AppEnv(),

      
      route : [],
      routeString: "",
      routeURL: "",
	  hostname: window.location.hostname

  
    
			   


    };
    

		getCollection(this.onComplete);

    }
   
	onComplete = (isLoad, user,collection,items,og,route) =>{ 
    
// When the code completes, do this
    
//console.log(isLoad, user,photoinfo,locationinfo,attachements,weather,within,wiki,yelps);

    this.setState({

                        isLoad : isLoad, 

                        firstName :user.firstName,
                        lastName : user.lastName,
                        img : user.img,
                        unreadMsgCount: user.unreadMsgCount,
                        fullName : user.fullName,

                        id: collection.id,
                        title: collection.title,
                        count: collection.count,
                        
                        month: collection.month,
                        day : collection.day,
                        year: collection.year,
                        url : collection.url,
                        thumb: collection.thumb,
                        thumbText : collection.thumbText,

                        items: items,

                        og:og,
                        env: AppEnv(),

                        route: route.route,
                        routeString: route.routeString,
                        routeURL : route.routeURL




                        } );
  }
	  
  
  componentDidMount(){
    this.setState({env: AppEnv()});
  }


  render(){
	//if (this.state.hostname !== "localhost"){ 

	
    //let appCollect = "stopsapp://stop/"+getStopIdString();
    
    // turn off yet
    /*
    setTimeout(function () {
      window.location.replace(appCollect); }
  , 2000);
  */
		
		
		//window.location = appCollect ;
		//let operationSystem = this.state.env.os;

		//window.location = appCollect;
    //window.open(appCollect,'_blank');

     

	//}
  return (
    <div className="app-collections-mobile">
        <div className="wrapper">
          <Meta og={this.state.og} />
          <DownloadBanner />
          <Navbar fullName={this.state.fullName} img={this.state.img} unreadMsgCount={this.state.unreadMsgCount}/>
          <TravelDashboard title={this.state.title} thumb={this.state.routeURL}  thumbText={this.state.thumbText}  count={this.state.count}/>
          <PinInfo  items={this.state.items} count={this.state.count} />
          <Features />
          <Footer />
        </div>
    </div>
  )}
}

export default AppCollectionsMobile;
