import React, { Component } from 'react';
import { BrowserRouter as Router, Route,  Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop.js";
import './App.css';
import BottomNav from './BottomNav/BottomNav.js';
import Stop from './Stop/Stop.js';
//import PixelStop from './PixelStop/PixelStop.js';
//import BusinessStop from './BusinessStop/BusinessStop.js';
import Collections from './Collections/Collections.js';
//import YouTubeStop from './YouTubeStop/YouTubeStop.js';


//import Cube from './Cube/Cube.js';


// import { slide as Menu } from 'react-burger-menu';
// import menuIcon from "./menu-icon.svg";
// import menuLogo from "./stops-menu-logo.png";
import {AppEnv} from './Storage/Environment.js';
import ReactGA from 'react-ga';
import Cube from './Cube/Cube.js';

//import { Switch } from '@material-ui/core';
//import { emit } from 'cluster';

class App extends Component {
    constructor(props) {
		//#region Asher.DataSet
        super(props);
        this.state = {
            isOpen: false,
            width: window.innerWidth,
            
            lhkbh   : "בס''ד",

            version : "2020-29-01",
            developer : "Stops! Asher Malkiel Sandler <asher.sandler770@gmail.com>",
			env:AppEnv()
        }
        this.handleHover = this.handleHover.bind(this);
        ReactGA.initialize('UA-105163537-1');
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    }

    handleHover() {
        this.setState({
            isOpen: true
        })
    }

    render () {
		
		//localStorage.clear();
    		
        //this.setState({storage:storage});
        const {width} = this.state;
        const isMobile = width <= 700;
        // const classes = useStyles();

        if (isMobile) {
            return (
                <div className="landing-pages-mobile">
                    <BottomNav />
                </div>
            );
        } else {
            return (
                <div className="landing-pages-desktop">
                    
                    <div className="right-side">
                    </div>
                        <Router>
                            <ScrollToTop>
                            <Switch>
                                <Route path= '/stop' component={Stop} />
                                <Route path="/collection" component={Collections} />
                                <Route path="/cube" component={Cube} />
                                <Route component={Stop} env={this.state.env}/>      
                            </Switch>
                            </ScrollToTop>
                        </Router>
                        {/*
                        <PixelStop />
                        <BusinessStop />
                        <Collections />
                        <YouTubeStop />
                        <Cube />
                        </ScrollToTop>
                    </Router>
                        */}
                </div>
            )
        }
    }
}

export default App;